<template>
  <div>
    <el-form-item :label="$t('显示阴影')">
      <l-select v-model="data.shadow" :options="shadowOptions" ></l-select>
    </el-form-item>
    <el-form-item :label="$t('自定义类')">
      <el-input v-model="data.myclass" ></el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-card",
  inject: ["formDesign"],
  props: ['data'],
  data () {
    return {
      shadowOptions:[
        {label:'总是',value:'always'},
        {label:'悬浮显示',value:'hover'},
        {label:'不显示',value:'never'}
      ]
    }
  },
  methods: {
  }
}
</script>

