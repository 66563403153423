<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="弹窗高">
        <el-input-number v-model="data.height"
                       controls-position="right"
                       placeholder="请输入"></el-input-number>
    </el-form-item>
    <el-form-item label="弹窗宽">
        <el-input-number v-model="data.width"
                       controls-position="right"
                       placeholder="请输入"></el-input-number>
    </el-form-item>
    <el-divider>字段绑定</el-divider>
        <el-form-item label="地址字段" style="margin-bottom:8px;" >
            <l-select v-model="data.bindaddr" :options="valueOptions"
                    placeholder="请输入"></l-select>
        </el-form-item>
        <el-form-item label="经纬度字段" style="margin-bottom:8px;" >
            <l-select v-model="data.bindaddrpoint" :options="valueOptions"
                    placeholder="请输入"></l-select>
        </el-form-item>
    <el-divider></el-divider>
    <el-form-item label="是否只读">
        <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item label="是否必填">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
import mixin from '../../../mixin'
export default {
    name: "config-layerbmap",
    mixins: [mixin()],
    inject: ["formDesign"],
    props: ['data'],
    data () {
        return{}
    },
    created(){
        
    },
    computed:{
        valueOptions(){
            const res = []
            const formInfo = this.formDesign.toSaveData()
            if(!this.data.subfield){
                formInfo.tabList.forEach(tab=>{
                    tab.components.forEach(com=>{
                        if(!['divider','gridtable'].includes(com.type) && com.prop != this.data.prop ){
                            res.push({value:com.prop,label:com.label})
                        }
                    })
                })
            }
            else{
                formInfo.tabList.forEach(tab=>{
                    tab.components.forEach(com=>{
                        if(['gridtable'].includes(com.type) && com.children.findIndex(t=>t.prop == this.data.prop) != -1 ){
                            com.children.forEach(c=>{
                                if(c.prop != this.data.prop ){
                                    res.push({value:c.prop,label:c.label})
                                }
                            })
                        }
                    })
                })
            }
            return res
        }
    },
    methods: {
       
    }
}
</script>

