<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="按钮类型">
      <l-select v-model="data.myType" :options="typeOptions"></l-select>
    </el-form-item>
    <el-form-item label="按钮尺寸">
      <el-radio-group v-model="data.size">
        <el-radio-button label="medium">{{ $t("中等") }}</el-radio-button>
        <el-radio-button label="small">{{ $t("较小") }}</el-radio-button>
        <el-radio-button label="mini">{{ $t("迷你") }}</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="按钮图标">
      <l-input-icon onlyFirst v-model="data.myIcon"> </l-input-icon>
    </el-form-item>
    <el-form-item label="朴素按钮">
      <el-switch v-model="data.plain"></el-switch>
    </el-form-item>
    <el-form-item label="圆角按钮">
      <el-switch v-model="data.round"></el-switch>
    </el-form-item>
    <el-form-item label="圆形按钮">
      <el-switch v-model="data.circle"></el-switch>
    </el-form-item>

    <el-form-item label="弹窗高">
      <el-input-number v-model="data.height" controls-position="right" placeholder="请输入"></el-input-number>
    </el-form-item>
    <el-form-item label="弹窗宽">
      <el-input-number v-model="data.width" controls-position="right" placeholder="请输入"></el-input-number>
    </el-form-item>

    <el-divider>数据</el-divider>
    <!--接口模式-->
    <div v-if="data.dataType == 4">
      <el-form-item label="选择接口">
        <l-layer-select
          v-model="data.dataCode"
          labelKey="f_Name"
          valueKey="f_Id"
          :columns="apiColumns"
          @change="handleLayerSelectChange"
          :loadPageData="loadPageData"
        ></l-layer-select>
      </el-form-item>
      <el-divider>回填字段绑定</el-divider>
      <div class="reg-item" :key="index" v-for="(item, index) in data.bindFields">
        <el-form-item label="组件名" style="margin-bottom: 8px">
          <el-input v-model="item.label" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="绑定字段" style="margin-bottom: 8px">
          <l-select v-model="item.value" :options="apiFieldOptions"></l-select>
        </el-form-item>
      </div>
    </div>

    <el-divider>数据列</el-divider>
    <div class="reg-item" :key="index" v-for="(item, index) in data.columns">
      <el-form-item label="列名" style="margin-bottom: 8px">
        <el-input v-model="item.label" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="绑定字段" style="margin-bottom: 8px">
        <l-select v-model="item.prop" :options="apiFieldOptions"></l-select>
      </el-form-item>
      <!-- <el-form-item label="赋值字段" style="margin-bottom: 8px">
          <l-select v-model="item.valueKey" :options="valueOptions" placeholder="请输入"></l-select>
        </el-form-item> -->
      <el-form-item label="宽度" style="margin-bottom: 8px">
        <el-input v-model="item.width" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="对齐" style="margin-bottom: 0">
        <l-select v-model="item.align" :options="alignOptions"></l-select>
      </el-form-item>
      <el-form-item label="隐藏" style="margin-bottom: 0">
        <el-switch v-model="item.hidden"></el-switch>
      </el-form-item>

      <el-button
        title="删除"
        @click="handleRemoveCol(index)"
        class="reg-item-delete"
        circle
        plain
        size="mini"
        type="danger"
      >
        <i class="el-icon-close"></i>
      </el-button>
    </div>
    <div class="mt-8">
      <el-button class="ml-8" type="primary" size="mini" @click="handleAddCol">添加列</el-button>
    </div>

    <el-divider></el-divider>
    <el-form-item label="是否只读">
      <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
import mixin from "../../../mixin";
export default {
  name: "config-backfill",
  mixins: [mixin()],
  inject: ["formDesign"],
  props: ["data"],
  data() {
    return {
      typeOptions: [
        { value: "primary", label: "primary" },
        { value: "success", label: "success" },
        { value: "warning", label: "warning" },
        { value: "danger", label: "danger" },
        { value: "info", label: "info" },
        { value: "text", label: "text" },
      ],
      alignOptions: [
        { value: "left", label: "靠左" },
        { value: "center", label: "居中" },
        { value: "right", label: "靠右" },
      ],

      apiNameList: [], //字段列表
      apiColumns: [
        { label: "数据名称", prop: "f_Name", minWidth: "110" },
        { label: "数据连接", prop: "f_Url", minWidth: "110" },
      ],
      apiResponseType: null,
    };
  },
  created() {},
  computed: {
    componentParamOptions() {
      const res = [];
      if (this.formDesign) {
        const formInfo = this.formDesign.toSaveData();
        formInfo.tabList.forEach((tab) => {
          tab.components.forEach((com) => {
            if (
              !["gridtable", "divider", "viewtable", "card", "btn", "backfill"].includes(com.type) &&
              com.prop != this.data.prop
            ) {
              res.push({ value: com.prop, label: com.label, field: `${com.table}_${com.field}` });
            }
          });
        });
      }
      return res;
    },
    apiFieldOptions() {
      if (this.data.apiResponseType) {
        return this.turnPropertysInfo(JSON.parse(this.data.apiResponseType)).map((item) => ({
          label: item,
          value: item,
        }));
      } else {
        return [];
      }
    },
  },

  methods: {
    handleLayerSelectChange(row) {
      if (row.f_Url.endsWith("/page")) {
        this.data.dataCode = row.f_Name;
        this.data.apiCode = row.f_Id;
        this.data.apiResponseType = row.f_ResponseType;
        this.data.bindFields = this.componentParamOptions.map((t) => {
          return {
            prop: t.value,
            label: t.label,
            field: t.field,
            value: "",
          };
        });
      } else {
        this.$message({
          type: "error",
          message: "请选择支持分页接口",
        });
      }
    },

    handleAddCol() {
      this.data.columns.push({
        label: `第${this.data.columns.length}列`,
        prop: "",
        valueKey: "",
        width: 100,
        align: "left",
        hidden: false,
      });
    },
    handleRemoveCol(index) {
      this.data.columns.splice(index, 1);
    },
    turnPropertysInfo(responseType) {
      let mainDbTables = [];
      let childDbTables = [];
      if (responseType.propertysInfo) {
        const mainData = responseType.propertysInfo.filter((item) => !item.propertysInfo.length);
        if (mainData.length) {
          //说明对象包含基本类型数据
          mainDbTables = mainData.map((item) => {
            return item.propertyName.toLowerCase();
          });
        }
        const childData = responseType.propertysInfo.filter((item) => item.propertysInfo.length);
        childDbTables = childData.flatMap((item) => {
          return item.propertysInfo.map((info) => {
            return info.propertyName.toLowerCase();
          });
        });
      }
      return mainDbTables.concat(childDbTables);
    },
  },
};
</script>
