export default [
  {
    title: "布局组件",
    list: [
      {
        type: "gridtable",
        label: "设计子表",
        icon: "learun-icon-layout-design-child-table",
        span: 24,
        isAddBtn: true,
        addText: "新增一行",
        isRemoveBtn: true,
        isShowNum: true,
        dataSource: [],
        children: [],
        changeDataEvent: "",
        isDESC: false,
      },
      {
        type: "card",
        label: "卡片布局",
        icon: "learun-icon-layout-card-layout",
        span: 24,
        children: [],
        shadow: "",
      },
      {
        type: "divider",
        label: "分割线",
        html: "分割线",
        contentPosition: "center",
        icon: "learun-icon-layout-divider",
        span: 24,
        labelWidth: 0,
        display: true,
      },
    ],
  },
  {
    title: "输入组件",
    list: [
      {
        type: "input",
        label: "单行文本",
        icon: "learun-icon-input-textfield",
        placeholder: "请输入",
        span: 24,
        patterns: [],
        display: true,
        default: "",
      },
      {
        type: "textarea",
        label: "多行文本",
        icon: "learun-icon-input-multiline-text",
        placeholder: "请输入",
        span: 24,
        display: true,
        default: "",
      },
      {
        type: "texteditor",
        label: "编辑器",
        icon: "learun-icon-input-editor",
        span: 24,
        display: true,
        default: "",
      },
      {
        type: "number",
        label: "计数器",
        icon: "learun-icon-input-counter",
        placeholder: "请输入",
        controlsPosition: "default",
        controls: true,
        span: 24,
        display: true,
        default: "",
        disabled: false,
      },
      {
        type: "password",
        label: "密码",
        icon: "learun-icon-input-password",
        placeholder: "请输入",
        span: 24,
        display: true,
        default: "",
      },
    ],
  },
  {
    title: "选择组件",
    list: [
      {
        type: "radio",
        label: "单选框组",
        icon: "learun-icon-choose-single-selection-box",
        span: 24,
        display: true,
        default: "1",
        dataType: "1", // 1 静态数据 2 数据字典 3 远端数据
        dataCode: "",
        dataValueKey: "",
        dataLabelKey: "",
        upCtrl: "",

        options: [
          {
            value: "1",
            label: "选项1",
          },
          {
            value: "2",
            label: "选项2",
          },
          {
            value: "3",
            label: "选项3",
          },
        ],
      },
      {
        type: "checkbox",
        label: "多选框组",
        icon: "learun-icon-choose-multiple-selection-box",
        span: 24,
        display: true,
        default: "",
        dataType: "1", // 1 静态数据 2 数据字典 3 远端数据
        dataCode: "",
        dataValueKey: "",
        dataLabelKey: "",
        upCtrl: "",
        options: [
          {
            value: "1",
            label: "选项1",
          },
          {
            value: "2",
            label: "选项2",
          },
          {
            value: "3",
            label: "选项3",
          },
        ],
      },
      {
        type: "select",
        label: "下拉选择",
        icon: "learun-icon-choose-pull-down-box",
        span: 24,
        display: true,
        placeholder: "请选择",

        default: "",
        dataType: "1", // 1 静态数据 2 数据字典 3 远端数据
        dataCode: "",
        dataValueKey: "",
        dataLabelKey: "",
        upCtrl: "",
        options: [
          {
            value: "1",
            label: "选项1",
          },
          {
            value: "2",
            label: "选项2",
          },
          {
            value: "3",
            label: "选项3",
          },
        ],
      },
      {
        type: "selectMultiple",
        label: "下拉多选",
        icon: "learun-icon-choose-drop-down-box",
        span: 24,
        display: true,
        multiple: true,
        placeholder: "请选择",

        default: "",
        dataType: "1", // 1 静态数据 2 数据字典 3 远端数据
        dataCode: "",
        dataValueKey: "",
        dataLabelKey: "",
        upCtrl: "",
        options: [
          {
            value: "1",
            label: "选项1",
          },
          {
            value: "2",
            label: "选项2",
          },
          {
            value: "3",
            label: "选项3",
          },
        ],
      },
      {
        key: 1,
        type: "treeselect",
        label: "树形选择",
        icon: "learun-icon-choose-tree-selection",
        span: 24,
        display: true,
        placeholder: "请选择",

        default: "",
        dataType: "1", // 1 静态数据 2 数据字典 3 远端数据
        dataCode: "",
        dataValueKey: "",
        dataLabelKey: "",
        dataIdKey: "",
        dataPIdKey: "",
        upCtrl: "",
        options: [
          {
            label: "选项一",
            value: "0",
            children: [
              {
                label: "选项1-1",
                value: "11",
              },
              {
                label: "选项1-2",
                value: "12",
              },
            ],
          },
          { label: "选项二", value: "1" },
          { label: "选项三", value: "2" },
        ],
      },
      {
        type: "layerselect",
        label: "弹窗选择",
        icon: "learun-icon-choose-popup-window",
        span: 24,
        placeholder: "请选择",
        display: true,

        multiple: false,
        isPage: true,
        columns: [],
        height: 504,
        width: 960,

        default: "",
        dataType: "2", // 2 数据字典 3 远端数据
        dataCode: "",
        dataValueKey: "",
        dataLabelKey: "",
        upCtrl: "",
      },
      /*,{
                key:1,
                type: 'cascader',
                label: '级联选择',
                icon: 'fa fa-share-alt',
                span: 24,
                display: true,
                placeholder:'请选择',

                default:[],
                dataType:'1', // 1 静态数据 2 数据字典 3 远端数据
                dataCode:'',
                dataValueKey:'',
                dataLabelKey:'',
                options: [
                    {
                        label: '选项一',
                        value: '0',
                        children: [{
                            label: '选项1-1',
                            value: '11',
                        }, {
                            label: '选项1-2',
                            value: '12',
                        }]
                    },
                    { label: '选项二', value: '1' },
                    { label: '选项三', value: '2' },
                ]
            }*/

      {
        type: "time",
        label: "时间选择",
        icon: "learun-icon-choose-time-selection",
        span: 24,
        display: true,
        default: "",
        placeholder: "选择时间",
        format: "HH:mm:ss",
        readonly: false,
        clearable: true,
        selectableRange: "",
      },
      {
        type: "timerange",
        label: "时间范围",
        icon: "learun-icon-choose-time-range",
        span: 24,
        display: true,

        startPlaceholder: "开始时间",
        endPlaceholder: "结束时间",
        format: "HH:mm:ss",
        default: "",
        readonly: false,
        clearable: true,
        isRange: true,
      },
      {
        type: "datetime",
        label: "日期选择",
        icon: "learun-icon-choose-date-selection",
        span: 24,
        display: true,
        dateType: "date",
        format: "yyyy-MM-dd",
        readonly: false,
        clearable: true,
        placeholder: "选择日期",
        default: "",
      },
      {
        type: "datetimerange",
        label: "日期范围",
        icon: "learun-icon-choose-date-selection",
        span: 24,
        display: true,
        isRange: true,
        dateType: "daterange",

        startPlaceholder: "开始时间",
        endPlaceholder: "结束时间",
        format: "yyyy-MM-dd",
        readonly: false,
        clearable: true,
        default: "",
      },
      {
        type: "areaselect",
        label: "省市区",
        icon: "learun-icon-choose-provinces",
        span: 24,
        placeholder: "请选择",
        display: true,

        default: "",
      },
      {
        type: "layerbmap",
        label: "地图选择",
        icon: "learun-icon-choose-nextmap-choose",
        span: 24,
        placeholder: "请输入地址",
        display: true,

        bindaddr: "",
        bindaddrpoint: "",
        height: 504,
        width: 960,
      },
    ],
  },
  {
    title: "上传组件",
    list: [
      {
        type: "upload",
        label: "文件上传",
        icon: "learun-icon-upload-file-upload",
        span: 24,
        display: true,
        maxSize: "2",
        sizeType: "MB",
        limit: 1,
        default: "",
      },
      {
        type: "uploadimg",
        label: "图片上传",
        icon: "learun-icon-upload-image-upload",
        span: 24,
        display: true,
        listType: "picture-card",
        maxSize: "2",
        sizeType: "MB",
        limit: 9,
        default: "",
      },
    ],
  },
  {
    title: "系统组件",
    list: [
      {
        type: "guid",
        label: "GUID主键",
        icon: "learun-icon-system-guid",
        span: 24,
        display: false,
        default: "",
      },
      {
        type: "companySelect",
        label: "公司选择",
        icon: "learun-icon-system-companies-choose",
        span: 24,
        display: true,
        isLogin: false,
        default: "",
      },
      {
        type: "departmentSelect",
        label: "部门选择",
        icon: "learun-icon-department-of-management",
        span: 24,
        display: true,
        isLogin: false,
        default: "",
      },
      {
        type: "userSelect",
        label: "人员选择",
        icon: "learun-icon-system-personnel-selection",
        span: 24,
        display: true,
        isLogin: false,
        default: "",
      },

      {
        type: "company",
        label: "所属公司",
        icon: "learun-icon-system-subordinate-companies",
        span: 24,
        display: false,
        default: "",
      },
      {
        type: "department",
        label: "所属部门",
        icon: "learun-icon-system-department",
        span: 24,
        display: false,
        default: "",
      },
      {
        type: "createuser",
        label: "创建人员",
        icon: "learun-icon-system-create-personnel",
        span: 24,
        display: false,
        default: "",
      },
      {
        type: "modifyuser",
        label: "修改人员",
        icon: "learun-icon-system-modify-staff",
        span: 24,
        display: false,
        default: "",
      },
      {
        type: "createtime",
        label: "创建时间",
        icon: "learun-icon-system-creation-time",
        span: 24,
        display: false,
        default: "",
      },
      {
        type: "modifytime",
        label: "修改时间",
        icon: "learun-icon-system-Modify-time",
        span: 24,
        display: false,
        default: "",
      },
      {
        type: "encode",
        label: "单据编码",
        icon: "learun-icon-documents-coding",
        span: 24,
        display: true,
        default: "",
        code: "",
      },
    ],
  },
  {
    title: "视图组件",
    list: [
      {
        type: "viewtable",
        label: "视图表格",
        icon: "learun-icon-view-view-form",
        span: 24,

        display: true,
        paramFiled: "",
        dataCode: "",
        columns: [],
      },
    ],
  },
  {
    title: "操作组件",
    list: [
      {
        type: "btn",
        label: "按钮组件",
        icon: "learun-icon-handle-button",
        span: 24,
        display: true,
        size: "mini",
        plain: false,
        round: false,
        circle: false,
        myIcon: "",
        myType: "",
      },
      {
        type: "backfill",
        label: "回填组件",
        icon: "learun-icon-handle-button",
        span: 24,
        display: true,
        size: "mini",
        plain: false,
        round: false,
        circle: false,
        myIcon: "",
        myType: "",
        columns: [],
        placeholder: "",
        height: 504,
        width: 960,
        dataType: 4,
        bindFields: [],
        dataCode: "",
        apiCode: "",
      },
    ],
  },
  {
    title: "其他组件",
    list: [
      {
        type: "icon",
        label: "图标",
        icon: "learun-icon-other-icon",
        span: 24,
        display: true,
        default: "",
      },
      {
        type: "rate",
        label: "评分",
        icon: "learun-icon-other-score",
        span: 24,
        display: true,
        max: 5,
        disabled: false,
        allowHalf: false,
        lowThreshold: 2,
        highThreshold: 4,
        colors: ["#F7BA2A", "#F7BA2A", "#F7BA2A"],
        voidColor: "#C6D1DE",
        disabledVoidColor: "#EFF2F7",
        iconClasses: ["el-icon-star-on", "el-icon-star-on", "el-icon-star-on"],
        voidIconClass: "el-icon-star-off",
        disabledVoidIconClass: "el-icon-star-on",
        showText: false,
        showScore: false,
        textColor: "#1F2D3D",
        texts: ["极差", "失望", "一般", "满意", "惊喜"],
        default: 0,
      },
      {
        type: "switch",
        label: "开关",
        icon: "learun-icon-other-on-off",
        span: 24,
        display: true,
        disabled: false,
        width: 40,
        default: false,
        activeIconClass: "",
        inactiveIconClass: "",
        activeText: "",
        inactiveText: "",
        valueType: "boolean",
        activeValue: "true",
        inactiveValue: "false",
        activeColor: "#409EFF",
        inactiveColor: "#C0CCDA",
      },
      {
        type: "slider",
        label: "滑块",
        icon: "learun-icon-other-slider",
        span: 24,
        display: true,
        default: 0,
        min: 0,
        max: 100,
        step: 1,
        showInput: false,
        showInputControls: true,
        showStops: false,
        showTooltip: true,
      },
      {
        type: "color",
        label: "颜色选择",
        icon: "learun-icon-other-color-choices",
        span: 24,
        default: "",
        display: true,
        disabled: false,
      },
    ],
  },
];
