import './styles/index.less'
import editor from './editor'
import formDesign from './formDesign'
import formViewer from './formViewer'

import config from '../package.json'

const prototypes = {
}
const components = {
  editor,
  formDesign,
  formViewer,
}

const install = function (Vue) {
  if (install.installed) {
    return
  }

  Object.values(components).map(component => {
    Vue.use(component)
  })

  Object.keys(prototypes).forEach((key) => {
    Vue.prototype['$' + key] = prototypes[key];
  });

  Vue.component('l-editor', Vue.component('quill-editor'));
}

//  全局引用可自动安装
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
  window.learun_form ={
    name:config.name,
    version:config.version,
    company:config.company
  }
}

export default {
  install,
  ...components
}

export {
  components
}
