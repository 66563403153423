<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="默认值">
      <el-input v-model="data.default"
                placeholder="默认值"></el-input>
    </el-form-item>
    <el-form-item label="前缀">
      <el-input v-model="data.prepend"
                placeholder="前缀"></el-input>
    </el-form-item>
    <el-form-item label="后缀">
      <el-input v-model="data.append"
                placeholder="后缀"></el-input>
    </el-form-item>
    <el-form-item label="前图标">
        <l-input-icon onlyFirst v-model="data.prefixIcon" >
        </l-input-icon>
    </el-form-item>
    <el-form-item label="后图标">
        <l-input-icon onlyFirst v-model="data.suffixIcon" >
        </l-input-icon>
    </el-form-item>
     
    <el-form-item label="最大长度">
      <el-input-number v-model="data.maxlength"
                       controls-position="right"
                       placeholder="请输入"></el-input-number>
    </el-form-item>
    <el-form-item label="是否可见">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
    <el-form-item label="是否只读">
      <el-switch v-model="data.readonly"></el-switch>
    </el-form-item>
    <el-divider>校验</el-divider>
    <el-form-item label="是否必填">
      <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
    <div class="reg-item" :key="index" v-for="(item,index) in data.patterns" >
        <el-form-item label="表达式">
            <el-input v-model="item.reg"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="错误提示" style="margin-bottom:0;" >
            <el-input v-model="item.msg"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-button title="删除"
                    @click="handleRemovePatterns(index)"
                    class="reg-item-delete"
                    circle
                    plain
                    size="mini"
                    type="danger">
            <i class="el-icon-close"></i>
        </el-button>
    </div>
    <div class="mt-8" >
        <el-dropdown @command="handleValidatorSelect" >
            <el-button type="primary" size="mini" >
                添加常用校验<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown"  >
                <el-dropdown-item :key="item.name" :command="item" v-for="item in validatorRules" >{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-button class="ml-8" type="primary" size="mini" @click="handleCustmerReg"  >自定义规则</el-button>
    </div>
  </div>
</template>

<script>

export default {
    name: "config-input",
    props: ['data'],
    data () {
        return {
        }
    },
    computed: {
        validatorRules(){
            if(this.lr_validatorRules){
              return this.lr_validatorRules
            }
            else{
              return []
            }
        }
    },
    methods: {
        handleValidatorSelect(val){
            this.data.patterns.push({
                msg:`${this.$t('请输入')}${val.name}`,
                reg:val.reg
            })
        },
        handleCustmerReg(){
            this.data.patterns.push({
                msg:'',
                reg:''
            })
        },
        handleRemovePatterns(index){
            this.data.patterns.splice(index,1)
        }
    }
}
</script>

