<template>
    <div>
        <el-divider>私有属性</el-divider>
        <el-form-item label="默认值">
            <l-time
                isRange
                clearable
                v-model="data.default"
                :format="data.format"
                :startPlaceholder="data.startPlaceholder"
                :endPlaceholder="data.endPlaceholder"
                placeholder="默认时间">
            </l-time>
        </el-form-item>
        <el-form-item label="时间格式">
            <el-input v-model="data.format" ></el-input>
        </el-form-item>
        <el-form-item label="是否清空">
            <el-switch v-model="data.clearable"></el-switch>
        </el-form-item>
        <el-form-item label="是否只读">
            <el-switch v-model="data.readonly"></el-switch>
        </el-form-item>
        <el-divider>校验</el-divider>
        <el-form-item label="是否必填">
            <el-switch v-model="data.required" ></el-switch>
        </el-form-item>
    </div>
</template>

<script>
export default {
  name: "config-timerange",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>