<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="默认值">
      <el-input v-model="data.default"
                placeholder="默认值"></el-input>
    </el-form-item>
    <el-form-item label="前缀">
      <el-input v-model="data.prepend"
                placeholder="前缀"></el-input>
    </el-form-item>
    <el-form-item label="后缀">
      <el-input v-model="data.append"
                placeholder="后缀"></el-input>
    </el-form-item>
    <el-form-item label="前图标">
        <l-input-icon :iconList="iconList"  v-model="data.prefixIcon" >
        </l-input-icon>
    </el-form-item>
    <el-form-item label="后图标">
        <l-input-icon :iconList="iconList"  v-model="data.suffixIcon" >
        </l-input-icon>
    </el-form-item>
     
    <el-form-item label="最大长度">
      <el-input-number v-model="data.maxlength"
                       controls-position="right"
                       placeholder="请输入字符长度"></el-input-number>
    </el-form-item>
    <el-form-item label="是否可见">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
    <el-form-item label="是否只读">
      <el-switch v-model="data.readonly"></el-switch>
    </el-form-item>
    <el-divider>校验</el-divider>
    <el-form-item label="是否必填">
      <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>


export default {
    name: "config-password",
    props: ['data'],
    data () {
        return {
        }
    },
    computed: {
        iconList(){
            if(this.lr_icons){
              return this.lr_icons
            }
            else{
              return []
            }
        }
    },
    methods: {
    }
}
</script>