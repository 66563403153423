<template>
  <div>
    <el-form-item label="排序字段">
      <el-select v-model="data.orderId" filterable placeholder="请选择">
          <el-option
            v-for="item in getFields(data.table)"
            :key="item.name"
            :label=" item.coment? `${item.name}(${item.coment})` : item.name"
            :value="item.name">
          </el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="data.table" :label="$t('是否倒序')">
      <el-switch v-model="data.isDESC" ></el-switch>
    </el-form-item>
    <el-form-item :label="$t('添加按钮')">
      <el-switch v-model="data.isAddBtn" ></el-switch>
    </el-form-item>
    <el-form-item :label="$t('按钮文本')">
        <el-input v-model="data.addText" ></el-input>
    </el-form-item>
    <el-form-item :label="$t('删除按钮')">
      <el-switch v-model="data.isRemoveBtn" ></el-switch>
    </el-form-item>
    <el-form-item :label="$t('序号列')">
      <el-switch v-model="data.isShowNum" ></el-switch>
    </el-form-item>
    <el-form-item label="是否必填">
      <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
    <div class="mt-8"  v-if="!isNoScript" >
        <el-button @click="handleBtnClick('changeDataEvent','添加行数据改变脚本')"  type="primary" size="mini" >添加行数据改变脚本</el-button>
    </div>
    <l-dialog 
        :title="formTitle"
        :visible.sync="formVisible"
        :height="528"
        :width="1000"
        @ok="handleCodeSave"
        @opened="handleOpenedForm"
        >
        <l-layout :right="320">
          <l-code-mirror v-model="code" isHint :handleHint="handleHint" mode="application/javascript" ></l-code-mirror>
          <template #right>
            <div class="l-rblock" style="padding:8px;overflow: auto;" >
                <el-alert
                    title="脚本参数说明,只支持ES5语法（兼容小程序）"
                    type="warning"
                    :closable="false"
                    >
                prop:组件id;
                <br/>data:组件改变数据;
                <br/>rowIndex:子表行号;
                <br/>isUpdate:表单状态,新增或更新;
                <br/>get(path):获取数据方法,
                如果想获取某一个组件值就设置path参数值为组件id,
                如果是子表的一行数据值就设置子表id.行号,
                如果是子表某一行某一列值就设置子表id.行号.子组件id;
                <br/>set(path,value):设置值方法,path说明和get方法一致;
                <br/>getLabel(prop):获取组件的显示值,prop为组件id;
                <br/>setRequired(prop,isRequired):设置组件是否必填,prop为组件id,isRequired 默认值true,如果取消必填就设置成false
                <br/>setDisabled(prop,isDisabled):设置组件是否只读,prop为组件id,isDisabled 默认值true,如果取消只读就设置成false
                <br/>setHide(prop,isHide):设置组件是否隐藏,isHide 默认值true,如果取消隐藏就设置成false
                <br/>httpGet(option):get请求方法
                <br/>httpPost(option):post请求方法
                <br/>httpDelete(option):delete请求方法
                <br/>httpPut(option):put请求方法
                <br/>option:上面四个请求方法参数描述:url:请求地址,data:提交数据(get方法不支持),params:url参数,errorTips:请求失败错误提示,callback请求回调方法
                返回结果为请求数据
                <br/>loading:显示加载状态
                <br/>hideLoading:隐藏加载状态
                <br/>message:显示提示消息
                <br/>loginUser:当前登录者信息
                <br/>callback:回调方法,在脚本里使用了http方法后才需要使用
                </el-alert>
            </div>
          </template>
        </l-layout>
    </l-dialog>
  </div>
</template>

<script>
export default {
  name: "config-gridtable",
  inject: ["formDesign"],
  props: ['data'],
  data () {
    return {
      formTitle:'',
      formVisible:false,
      code:'',
      type:''
    }
  },
  computed:{
    isNoScript(){
      return this.formDesign.isNoScript;
    }
  },
  methods: {
    handleBtnClick(type,title){
        this.type = type
        this.formTitle = title
        this.formVisible = true
    },
    getEvent(strEvent){
      // 获取事件方法
      if(!this.$validatenull(strEvent)){
          if(strEvent.indexOf('=>') != -1){
              // 表示是老版本，提示其修改为新的版本
              console.warn('当前脚本不支持ES6语法，只支持ES5语法')
              return  {res:false,msg:'脚本没有更新为最新的版本！'}
          }
          else{
              strEvent = `(function(){function fn(learun) {${strEvent}} return fn})()`
              return this.$getFunction(strEvent)
          }
      }
      else{
         return  {res:true}
      }
    },
    handleCodeSave(){
        const {res,msg} = this.getEvent(this.code)
        if(res){
            this.data[this.type] = this.code
            this.formVisible = false
        }
        else{
            this.$message({
                type: 'error',
                message: `脚本错误:${msg}`
            })
        }
    },
    handleOpenedForm(){
      this.code = this.data[this.type] || ''
    },
    getFields(tableName){
      const table = this.formDesign.dbTables.find(t=>t.name == tableName) || {}
      return table.columns || []
    },
    handleHint(text){
        text = text.replace(/'/g,'')
        text = text.replace(/"/g,'')
        const myList = this.formDesign.nowComponentList.filter(t=>t.pprop == this.data.prop  && (t.label.indexOf(text) != -1 ||  t.prop.indexOf(text) != -1))
        const list = this.formDesign.nowComponentList.filter(t=>t.pprop != this.data.prop  && (t.label.indexOf(text) != -1 ||  t.prop.indexOf(text) != -1))

        myList.push(...list)
        return myList.map(t=>`/*${t.label}*/"${t.prop}"`)
    }
  }
}
</script>

