<template>
  <div>
    <el-row>
      <draggable
        class="drag-form-table__content"
        :list="tableComponent.children"
        :group="{ name: 'form' }"
        ghost-class="ghost"
        :animation="200"
        handle=".drag-form-table__item"
        @add="handleWidgetTableAdd($event, tableComponent)"
        @end="$emit('change')"
      >
        <template v-for="(column, index) in tableComponent.children">
          <div
            class="drag-form-table__item drag-form-table"
            v-if="column.type == 'gridtable'"
            :key="column.prop"
            :class="{ active: selectWidget.prop == column.prop }"
            @click.stop="handleSelectTableWidget(index)"
          >
            <div class="drag-form-table__title">{{ $t(column.label) }}</div>
            <drag-form-table
              :tableComponent="column"
              :index="index"
              :select.sync="selectWidget"
              :plist="tableComponent.children"
              :ref="column.prop"
              @change="$emit('change')"
            ></drag-form-table>
          </div>
          <div
            class="drag-form-table__item drag-form-table viewtable"
            v-else-if="column.type == 'viewtable'"
            :key="column.prop"
            :class="{ active: selectWidget.prop == column.prop }"
            @click.stop="handleSelectTableWidget(index)"
          >
            <div class="drag-form-table__title">{{ $t(column.label) }}</div>
            <l-table :columns="column.columns"></l-table>
            <el-button
              title="复制"
              @click.stop="handleWidgetTableClone(column)"
              class="drag-action-clone"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="primary"
            >
              <i class="el-icon-copy-document"></i>
            </el-button>
            <el-button
              title="删除"
              @click.stop="handleWidgetTableDelete(index)"
              class="drag-action-delete"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="danger"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </div>
          <el-col
            v-else
            class="drag-form-table__item"
            :key="column.prop"
            :md="column.span || 12"
            :xs="24"
            :offset="column.offset || 0"
          >
            <div
              class="drag-form-table"
              v-if="column.type == 'card'"
              :key="column.prop"
              :class="{ active: selectWidget.prop == column.prop }"
              @click.stop="handleSelectTableWidget(index)"
            >
              <div class="drag-form-table__title">{{ $t(column.label) }}</div>
              <drag-form-card
                :tableComponent="column"
                :index="index"
                :select.sync="selectWidget"
                :plist="tableComponent.children"
                :ref="column.prop"
                @change="$emit('change')"
              >
              </drag-form-card>
            </div>

            <div
              class="drag-form-table drag-form-btn"
              v-else-if="column.type == 'backfill'"
              :key="column.prop"
              :class="{ active: selectWidget.prop == column.prop }"
              @click.stop="handleSelectTableWidget(index)"
            >
              <l-backfill v-bind="column"></l-backfill>
              <el-button
                title="复制"
                @click.stop="handleWidgetTableClone(column)"
                class="drag-action-clone"
                v-if="selectWidget.prop == column.prop"
                circle
                plain
                size="mini"
                type="primary"
              >
                <i class="el-icon-copy-document"></i>
              </el-button>
              <el-button
                title="删除"
                @click.stop="handleWidgetTableDelete(index)"
                class="drag-action-delete"
                v-if="selectWidget.prop == column.prop"
                circle
                plain
                size="mini"
                type="danger"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </div>

            <div
              class="drag-form-table drag-form-btn"
              v-else-if="column.type == 'btn'"
              :key="column.prop"
              :class="{ active: selectWidget.prop == column.prop }"
              @click.stop="handleSelectTableWidget(index)"
            >
              <el-button
                :size="column.size"
                :plain="column.plain"
                :round="column.round"
                :circle="column.circle"
                :icon="column.myIcon"
                :type="column.myType"
                >{{ column.label }}</el-button
              >
              <el-button
                title="复制"
                @click.stop="handleWidgetTableClone(column)"
                class="drag-action-clone"
                v-if="selectWidget.prop == column.prop"
                circle
                plain
                size="mini"
                type="primary"
              >
                <i class="el-icon-copy-document"></i>
              </el-button>
              <el-button
                title="删除"
                @click.stop="handleWidgetTableDelete(index)"
                class="drag-action-delete"
                v-if="selectWidget.prop == column.prop"
                circle
                plain
                size="mini"
                type="danger"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </div>
            <div v-else @click.stop="handleSelectTableWidget(index)">
              <el-form-item
                :label="column.label"
                :prop="column.prop"
                :required="column.required"
                :class="{ active: selectWidget.prop == column.prop, required: column.required }"
                :show-message="false"
              >
                <drag-form-item :data="column"></drag-form-item>
                <el-button
                  title="复制"
                  @click.stop="handleWidgetTableClone(column)"
                  class="drag-action-clone"
                  v-if="selectWidget.prop == column.prop"
                  circle
                  plain
                  size="mini"
                  type="primary"
                >
                  <i class="el-icon-copy-document"></i>
                </el-button>
                <el-button
                  title="删除"
                  @click.stop="handleWidgetTableDelete(index)"
                  class="drag-action-delete"
                  v-if="selectWidget.prop == column.prop"
                  circle
                  plain
                  size="mini"
                  type="danger"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </el-form-item>
            </div>
          </el-col>
        </template>
      </draggable>
    </el-row>

    <el-button
      title="复制"
      @click.stop="handleWidgetCloneTable(index)"
      class="drag-table-action-clone"
      v-if="selectWidget.prop == tableComponent.prop"
      circle
      plain
      size="mini"
      type="primary"
    >
      <i class="el-icon-copy-document"></i>
    </el-button>
    <el-button
      title="删除"
      @click.stop="handleWidgetDeleteTable(index)"
      class="drag-table-action-delete"
      v-if="selectWidget.prop == tableComponent.prop"
      circle
      plain
      size="mini"
      type="danger"
    >
      <i class="el-icon-delete"></i>
    </el-button>
  </div>
</template>
<script>
import dragFormItem from "./dragFormItem";
import dragFormTable from "./dragFormTable";

export default {
  name: "drag-form-card",
  props: ["tableComponent", "select", "index", "plist"],
  components: { dragFormTable, dragFormItem },
  inject: ["formDesign"],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    selectWidget: {
      get() {
        return this.select;
      },
      set(val) {
        this.$emit("update:select", val);
      },
    },
    formComponents() {
      return this.plist || []; //this.formDesign.formInfo.tabList[this.myTabsIndex].components || [];
    },
    dbTables() {
      return this.formDesign.dbTables;
    },
  },
  methods: {
    handleSelectWidget(index) {
      this.selectWidget = this.formComponents[index];
    },
    handleWidgetDeleteTable(index) {
      if (this.formComponents.length - 1 === index) {
        if (index === 0) this.selectWidget = {};
        else this.handleSelectWidget(index - 1);
      } else this.handleSelectWidget(index + 1);

      this.$nextTick(() => {
        this.formComponents.splice(index, 1);
        this.$emit("change");
      });
    },
    handleWidgetCloneTable(index) {
      let cloneData = this.$deepClone(this.tableComponent);
      cloneData.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      cloneData.table = "";
      cloneData.field = "";
      this.setAllProp(cloneData.children);
      this.formComponents.splice(index, 0, cloneData);
      this.$nextTick(() => {
        this.handleSelectWidget(index + 1);
        this.$emit("change");
      });
    },
    setAllProp(list) {
      list.forEach((t) => {
        t.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
        if (t.children && t.children.length > 0) {
          this.setAllProp(t.children);
        }
      });
    },
    handleSelectTableWidget(index) {
      this.selectWidget = this.tableComponent.children[index];
    },
    handleFormClickAdd(item) {
      this.tableComponent.children.push(item);
      this.handleWidgetTableAdd({ newIndex: this.tableComponent.children.length - 1 }, this.tableComponent, true);
    },
    handleWidgetTableAdd(evt, tableComponent, isNotSelect) {
      let newIndex = evt.newIndex;
      if (newIndex == 1 && newIndex > tableComponent.children.length - 1) newIndex = 0;
      const data = this.$deepClone(tableComponent.children[newIndex]);
      if (!data.prop) data.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);

      if (data.subfield) {
        data.table = "";
        data.field = "";
      }
      if (data.type == "gridtable") {
        // 如果是子表
        const tables = this.formDesign.dbTables.filter((t) => t.type == "chlid");
        if (tables.length == 1) {
          data.table = tables[0].name;
        }
      } else if (!["divider", "viewtable", "card", "btn"].includes(data.type)) {
        // 如果只有一个表的时候,默认赋值
        if (this.dbTables.length == 1) {
          data.table = this.dbTables[0].name;
        }
      }

      if (data.subfield && data.type == "layerselect") {
        data.columns.forEach((col) => {
          col.valueKey = "";
        });
      }

      delete data.icon;
      delete data.subfield;
      this.$set(tableComponent.children, newIndex, { ...data });
      if (!isNotSelect) {
        this.selectWidget = tableComponent.children[newIndex];
      }
      this.$emit("change");
    },
    handleWidgetTableClone(item) {
      const data = this.$deepClone(item);
      data.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      this.$set(this.tableComponent.children, this.tableComponent.children.length, { ...data });
      this.$nextTick(() => {
        this.selectWidget = this.tableComponent.children[this.tableComponent.children.length - 1];
        this.$emit("change");
      });
    },
    handleWidgetTableDelete(index) {
      if (this.tableComponent.children.length - 1 == index) {
        if (index == 0) this.selectWidget = this.tableComponent;
        else this.selectWidget = this.tableComponent.children[index - 1];
      } else this.selectWidget = this.tableComponent.children[index + 1];
      this.$nextTick(() => {
        this.tableComponent.children.splice(index, 1);
        this.$emit("change");
      });
    },
  },
};
</script>
