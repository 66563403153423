<template>
  <el-card :class="data.myclass" :header="data.label" :shadow="data.shadow">
    <template v-for="col in data.children">
      <el-col style="margin-bottom: 16px" v-if="['gridtable'].includes(col.type)" :key="col.prop" :span="24">
        <div v-if="!$validatenull(col.label)" class="l-title">{{ $t(col.label) }}</div>
        <l-edit-table
          :required="col.required"
          :addText="col.addText"
          :isAddBtn="col.isAddBtn"
          :isRemoveBtn="col.isRemoveBtn"
          :isShowNum="col.isShowNum"
          :columns="getTableColumns(col.children)"
          :dataSource="formData[col.prop]"
          :filterDeleteBtn="filterTableDeleteBtn"
          :isRead="isRead"
          @add="handleAddRow(col)"
          @delete="handleDeleteRow($event, col)"
          :ref="col.prop"
        >
          <template v-for="column in col.children" v-slot:[column.prop]="scope">
            <viewer-form-item
              :key="column.prop"
              v-model="scope.row[column.prop]"
              :isRead="isRead"
              :upCtrls="upCtrls"
              :formData="scope.row"
              :data="column"
              @change="handleItemChange($event, col, scope.$index, scope.row)"
            >
            </viewer-form-item>
          </template>
        </l-edit-table>
      </el-col>
      <el-col style="margin-bottom: 16px" v-else-if="['viewtable'].includes(col.type)" :key="col.prop" :span="24">
        <div v-if="!$validatenull(col.label)" class="l-title">{{ $t(col.label) }}</div>
        <l-view-table :paramFiled="formData[col.paramFiled]" :columns="col.columns" :code="col.dataCode">
        </l-view-table>
      </el-col>
      <el-col v-else-if="!['gridtable'].includes(col.type)" :key="col.prop" :span="col.span">
        <viewer-form-item v-if="col.type == 'divider'" :data="col"></viewer-form-item>
        <card-viewer v-else-if="col.type == 'card'" :data="col"></card-viewer>

        <el-form-item v-else-if="col.type == 'btn'" v-show="col.display && !isRead" labelWidth="0px">
          <el-button
            :size="col.size"
            :plain="col.plain"
            :round="col.round"
            :circle="col.circle"
            :icon="col.myIcon"
            :type="col.myType"
            :disabled="col.disabled"
            @click="componentBtnClick(col)"
            >{{ col.label }}</el-button
          >
        </el-form-item>

        <el-form-item v-else-if="col.type == 'backfill'" v-show="col.display && !isRead" labelWidth="0px">
          <l-backfill v-bind="col" :formData="formViewer.formData"></l-backfill>
        </el-form-item>
        <el-form-item
          v-else
          v-show="col.display"
          :label="col.label"
          :labelWidth="col.labelWidth == undefined ? undefined : col.labelWidth + 'px'"
          :prop="col.prop"
        >
          <viewer-form-item
            @change="handleItemChange"
            :isRead="isRead"
            :upCtrls="upCtrls"
            :isForm="true"
            v-model="formData[col.prop]"
            :data="col"
            :formData="formData"
          ></viewer-form-item>
        </el-form-item>
      </el-col>
    </template>
  </el-card>
</template>

<script>
import viewerFormItem from "./viewerFormItem";

export default {
  name: "card-viewer",
  components: {
    viewerFormItem,
  },
  inject: ["formViewer"],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    formData() {
      return this.formViewer.formData;
    },
    upCtrls() {
      return this.formViewer.upCtrls;
    },
    isRead() {
      return this.formViewer.isRead;
    },
  },
  methods: {
    getTableColumns(list) {
      return this.formViewer.getTableColumns(list);
    },
    handleAddRow(event) {
      return this.formViewer.handleAddRow(event);
    },
    handleDeleteRow(event, col) {
      return this.formViewer.handleDeleteRow(event, col);
    },
    componentBtnClick(col) {
      return this.formViewer.componentBtnClick(col);
    },
    filterTableDeleteBtn(row) {
      return this.formViewer.filterTableDeleteBtn(row);
    },
    handleItemChange(event, col, tableIndex, tableRow) {
      return this.formViewer.handleItemChange(event, col, tableIndex, tableRow);
    },
  },
};
</script>
