<template>
  <div>
    <l-editor
      v-if="data.type == 'texteditor'"
      v-model="data.default"
      :options="{ theme: 'snow', placeholder: $t('请输入内容...') }"
    >
    </l-editor>
    <component
      v-else
      :is="getComponent(data.type)"
      v-model="data.default"
      v-bind="$deepClone(data)"
      :placeholder="data.placeholder"
      :options="myOptions"
    >
      <span v-if="data.html" v-html="data.html"></span>
      <template v-if="['input'].includes(data.type) && data.prepend" #prepend>
        {{ data.prepend }}
      </template>
      <template v-if="['input'].includes(data.type) && data.append" #append>
        {{ data.append }}
      </template>
    </component>
  </div>
</template>
<script>
import mixin from "../../mixin";
export default {
  name: "drag-form-item",
  mixins: [mixin()],
  inject: ["formDesign"],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    toValueType(type, value) {
      if (!this.$validatenull(value) && !this.$validatenull(type)) {
        switch (type) {
          case "boolean":
            return value == "true";
          case "string":
            return value;
          case "number":
            return Number(value);
        }
      }
    },
  },
};
</script>
