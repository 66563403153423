<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="默认值">
        <l-area-select v-model="data.default"></l-area-select>
    </el-form-item>
    <el-form-item label="是否可见">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
    <el-divider>校验</el-divider>
    <el-form-item label="是否必填">
      <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-areaselect",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

