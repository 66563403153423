<template>
  <div>
    <el-form-item label="默认值">
        <el-cascader 
            :key="data.key"
            v-model="value"
            placeholder="请选择默认值" 
            size="mini"
            :props="{ multiple: true }"
            :options="data.options"
            >
        </el-cascader>
    </el-form-item>
    <el-divider>选项</el-divider>
    <div style="text-align: center;margin-bottom:16px;" >
        <el-radio-group v-model="data.dataType" size="mini" @change="handleDataTypeChange" >
            <el-radio-button label="1">静态数据</el-radio-button>
            <el-radio-button label="2">数据字典</el-radio-button>
            <el-radio-button label="3">数据源</el-radio-button>
        </el-radio-group>
    </div>
    <!--静态数据-->
    <div v-if="data.dataType == 1" >
        <el-tree ref="tree"
                   :data="data.options"
                   default-expand-all
                   draggable
                   node-key="value"
                   :expand-on-click-node="false">
            <span class="custom-tree-node"
                  slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                    <el-button type="text"
                            size="mini"
                            icon="el-icon-plus"
                            @click="handleNodeAdd(data)"></el-button>
                <!--                <el-button class="warning" type="text" size="mini" icon="el-icon-edit"-->
                <!--                           @click="handleNodeEdit(data)"></el-button>-->
                    <el-button class="danger"
                            type="text"
                            size="mini"
                            icon="el-icon-delete"
                            @click="handleNodeRemove(node, data)"></el-button>
                </span>
            </span>
        </el-tree>
        <div style="margin-left: 22px;">
            <el-button size="mini" type="text" icon="el-icon-circle-plus-outline" @click="handleParentNodeAdd"  >添加父级</el-button>
        </div>
    </div>
    <!--数据字典-->
    <div v-else-if="data.dataType == 2" >
        <l-tree-select
            v-model="data.dataCode"
            :data="dataItemClassifysTree"
            placeholder="请选择数据字典"
            size="mini"
            :parent="false"

            @change="handleDataItemChange"
        >
        </l-tree-select>
    </div>
    <!--远端数据-->
    <div v-else >
        <el-form-item label="数据源">
            <l-select  
                    v-model="data.dataCode"
                    placeholder="请选择数据源" 
                    size="mini" 
                    :options="lr_dataSource" 
                    labelKey="f_Name" 
                    valueKey="f_Code"

                    @change="handleDataSourceChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item label="值字段">
            <l-select  
                    v-model="data.dataValueKey"
                    placeholder="请选择选项值字段" 
                    size="mini" 
                    :options="colNames" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item label="名字段">
            <l-select  
                    v-model="data.dataLabelKey"
                    placeholder="请选择选项名字段" 
                    size="mini" 
                    :options="colNames" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item label="id字段">
            <l-select  
                    v-model="data.dataIdKey"
                    placeholder="请选择选项id字段" 
                    size="mini" 
                    :options="colNames" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item label="pid字段">
            <l-select  
                    v-model="data.dataPIdKey"
                    placeholder="请选择选项父级id字段" 
                    size="mini" 
                    :options="colNames" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
    </div>
    
    <el-divider></el-divider>
    <el-form-item label="是否只读">
        <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item label="是否必填">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>


    <l-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :height="200"

        @close="closeDialog"
        @ok="handleDialogAdd"
        >
        <div class="l-from-body" > 
            <el-form :model="dialogForm" size="mini" :rules="dialogRules"  ref="dialogForm" label-width="80px"  >
                <el-form-item label="选项名" prop="label">
                    <el-input v-model="dialogForm.label"></el-input>
                </el-form-item>
                <el-form-item label="选项值" prop="value">
                    <el-input v-model="dialogForm.value"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </l-dialog>
  </div>
</template>

<script>
export default {
    name: "config-cascader",
    props: ['data'],
    data () {
        return {
            dialogTitle:'添加选项',
            dialogVisible:false,
            dialogForm: {},
            dialogRules: {
                label: { required: true, message: '请输入选项名' ,trigger:'null' },
                value: { required: true, message: '请输入选项值' ,trigger:'null'},
            },
            selectData:undefined,

            colNames:[],
            dataSourceData:[],


            value:[],

        }
    },
    computed:{
        dataItemClassifysTree(){
            return this.$toTree(this.lr_dataItemClassifys,"f_ItemId","f_ParentId","f_ItemCode","f_ItemName")
        }
    },
    watch:{
        value(newVal){
            console.log(newVal);
        }
    },
    methods: {
        clearValue(){
            this.$set(this.data, 'default', []);
            this.data.key++;
        },
        handleDataTypeChange(){
            this.clearValue();
            this.data.dataCode = undefined;
            this.data.dataIdKey = undefined;
            this.data.dataPIdKey = undefined;
            this.data.dataValueKey = undefined;
            this.data.dataLabelKey = undefined;
            this.data.options = [];
            this.dataSourceData = [];
        },
        handleParentNodeAdd(){
            this.selectData = undefined;
            this.dialogTitle = '添加父级选项'
            this.dialogVisible = true;
        },
        handleNodeAdd(data){
            console.log(data);
            this.selectData = data;
            this.dialogTitle = `添加【${data.label}】的子选项`
            this.dialogVisible = true;
        },
        handleNodeRemove(node, data){
            this.clearValue();
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            children.splice(index, 1);
            
        },
        handleDialogAdd () {
            this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    const { label, value } = this.dialogForm;
                    const node = this.$refs.tree.getNode(value)
                    if (node) this.$message.error("选项值重复")
                    else {
                        const data = this.selectData
                        const newNode = {
                            label,
                            value: this.dialogInputType == 'number' ? Number(value) : value,
                        }
                        if (data) {
                            if (!data.children) this.$set(data, 'children', [])
                                data.children.push(newNode)
                        } else {
                            this.$set(this.data.options, this.data.options.length, newNode)
                        }
                        this.dialogVisible = false
                    }
                }
            })
        },
        closeDialog () {
            this.$refs.dialogForm.clearValidate()
            this.dialogForm = {}
        },
        handleDataItemChange(val){
            if(!val) return;
            this.clearValue();
            this.lr_updateDataItemDetails(val).then(res => {
                this.data.options = this.$toTree(res,'f_ItemDetailId','f_ParentId','f_ItemValue','f_ItemName');
            })
        },
        handleDataSourceChange(val){
            if(!val) return;
            this.clearValue();
            this.lr_updateDataSourceColName(val).then(res => {
                const _data = [];
                res.forEach(item => {
                    var point = {
                        value:item,
                        label:item
                    }
                    _data.push(point);
                });

                this.colNames = _data;
                this.data.dataValueKey = res[0];
                this.data.dataLabelKey = res[0];
                this.data.dataIdKey = res[0];
                this.data.dataPIdKey = res[0];
                this.lr_updateDataSourceData(val).then(res2 => {
                    this.data.options = this.$toTree(res2,res[0],res[0],res[0],res[0]);
                    this.dataSourceData = res2;
                })
            })
        },
        handleDataSourceKeyLabelChange(){
            console.log('test')
            this.data.options = this.$toTree(this.dataSourceData,this.data.dataIdKey,this.data.dataPIdKey,this.data.dataValueKey,this.data.dataLabelKey);
        }
    }
}
</script>

<style lang="less" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>