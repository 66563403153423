<template>
  <div>
    <el-form-item label="单据编码">
        <l-select  
                v-model="data.code"
                :placeholder="$t('请选择单据编码')"
                size="mini"
                :options="codeList" 
            >
        </l-select>
    </el-form-item>
    <el-form-item label="是否可见">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-encode",
  props: ['data'],
  data () {
    return {
    }
  },
  computed:{
    codeList(){
      if(this.lr_codeList){
        const list = this.lr_codeList || []
        return list.map(t=>{
          return {label:t.f_FullName,value:t.f_EnCode}
        })
      }
      else{
        return []
      }
    }
  },
  created(){
    this.lr_loadCodeList && this.lr_loadCodeList()
  },
  methods: {
  }
}
</script>