<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="按钮类型">
        <l-select v-model="data.myType" :options="typeOptions" ></l-select>
    </el-form-item>
    <el-form-item label="按钮尺寸">
        <el-radio-group v-model="data.size">
            <el-radio-button label="medium">{{$t('中等')}}</el-radio-button>
            <el-radio-button label="small">{{$t('较小')}}</el-radio-button>
            <el-radio-button label="mini">{{$t('迷你')}}</el-radio-button>
        </el-radio-group>
    </el-form-item>
    <el-form-item label="按钮图标">
        <l-input-icon onlyFirst  v-model="data.myIcon" >
        </l-input-icon>
    </el-form-item>   
    <el-form-item label="朴素按钮">
      <el-switch v-model="data.plain" ></el-switch>
    </el-form-item>
    <el-form-item label="圆角按钮">
      <el-switch v-model="data.round" ></el-switch>
    </el-form-item>
    <el-form-item label="圆形按钮">
      <el-switch v-model="data.circle" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-btn",
  inject: ["formDesign"],
  props: ['data'],
  data () {
    return {
      typeOptions:[
        {value:'primary',label:'primary'},
        {value:'success',label:'success'},
        {value:'warning',label:'warning'},
        {value:'danger',label:'danger'},
        {value:'info',label:'info'},
        {value:'text',label:'text'},
      ]
    }
  },
  methods: {
  }
}
</script>

