<template>
  <div>
    <el-form-item label="默认值">
      <l-layer-select
        v-model="data.default"
        v-bind="data"
        placeholder="请选择默认值"
        size="mini"
        :multiple="data.multiple"
        :columns="data.columns"
        :isPage="data.isPage"
      >
      </l-layer-select>
    </el-form-item>
    <el-divider>私有属性</el-divider>
    <el-form-item label="是否多选">
      <el-switch v-model="data.multiple"></el-switch>
    </el-form-item>
    <!-- <el-form-item label="是否分页">
      <el-switch v-model="data.isPage"></el-switch>
    </el-form-item> -->
    <el-form-item label="弹窗高">
      <el-input-number v-model="data.height" controls-position="right" placeholder="请输入"></el-input-number>
    </el-form-item>
    <el-form-item label="弹窗宽">
      <el-input-number v-model="data.width" controls-position="right" placeholder="请输入"></el-input-number>
    </el-form-item>
    <el-divider>数据</el-divider>
    <div style="text-align: center; margin-bottom: 16px">
      <el-radio-group v-model="data.dataType" size="mini" @change="handleDataTypeChange">
        <el-radio-button label="2">数据字典</el-radio-button>
        <el-radio-button label="3">数据源</el-radio-button>
        <el-radio-button label="4" v-if="formDesign.formType == 2">接口</el-radio-button>
      </el-radio-group>
    </div>
    <!--数据字典-->
    <div v-if="data.dataType == 2">
      <l-tree-select
        v-model="data.dataCode"
        :options="dataItemClassifysTree"
        placeholder="请选择数据字典"
        size="mini"
        :parent="false"
        @change="handleDataItemChange"
      >
      </l-tree-select>
    </div>
    <!--接口模式-->
    <div v-else-if="data.dataType == 4">
      <el-form-item label="选择接口">
        <l-layer-select
          v-model="data.dataCode"
          labelKey="f_Name"
          valueKey="f_Id"
          :columns="apiColumns"
          :options="apiArr"
          @change="handleLayerSelectChange"
          :loadPageData="loadPageData"
        ></l-layer-select>
      </el-form-item>
      <el-form-item label="选项值">
        <l-select
          v-model="data.dataValueKey"
          placeholder="请选择选项值"
          size="mini"
          :options="apiNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="选项名">
        <l-select
          v-model="data.dataLabelKey"
          placeholder="请选择选项名"
          size="mini"
          :options="apiNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="上一级">
        <l-select
          v-model="data.upCtrl"
          placeholder="请选择"
          size="mini"
          :options="componentOptions"
          @change="componentOptionsChange"
        >
        </l-select>
      </el-form-item>
    </div>
    <!--远端数据-->
    <div v-else>
      <el-form-item label="数据源">
        <l-select
          v-model="data.dataCode"
          placeholder="请选择数据源"
          size="mini"
          :options="dataSource"
          labelKey="f_Name"
          valueKey="f_Code"
          @change="handleDataSourceChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="值">
        <l-select
          v-model="data.dataValueKey"
          placeholder="请选择值"
          size="mini"
          :options="myColNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="标签">
        <l-select
          v-model="data.dataLabelKey"
          placeholder="请选择标签"
          size="mini"
          :options="myColNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>

      <el-form-item label="上一级">
        <l-select v-model="data.upCtrl" placeholder="请选择" size="mini" :options="componentOptions"> </l-select>
      </el-form-item>
    </div>
    <el-divider>数据列</el-divider>
    <div class="reg-item" :key="index" v-for="(item, index) in data.columns">
      <el-form-item label="列名" style="margin-bottom: 8px">
        <el-input v-model="item.label" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="绑定字段" style="margin-bottom: 8px">
        <l-select v-model="item.prop" :options="layerSelectMyColNameList"></l-select>
      </el-form-item>
      <el-form-item label="赋值字段" style="margin-bottom: 8px">
        <l-select
          v-model="item.valueKey"
          @change="valueKeyChange(item, $event)"
          :options="valueOptions"
          placeholder="请输入"
        ></l-select>
      </el-form-item>
      <el-form-item label="宽度" style="margin-bottom: 8px">
        <el-input v-model="item.width" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="对齐" style="margin-bottom: 0">
        <l-select v-model="item.align" :options="alignOptions"></l-select>
      </el-form-item>
      <el-form-item label="隐藏" style="margin-bottom: 0">
        <el-switch v-model="item.hidden"></el-switch>
      </el-form-item>

      <el-button
        title="删除"
        @click="handleRemoveCol(index)"
        class="reg-item-delete"
        circle
        plain
        size="mini"
        type="danger"
      >
        <i class="el-icon-close"></i>
      </el-button>
    </div>
    <div class="mt-8">
      <el-button class="ml-8" type="primary" size="mini" @click="handleAddCol">添加列</el-button>
    </div>

    <el-divider></el-divider>
    <el-form-item label="是否只读">
      <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item label="是否必填">
      <el-switch v-model="data.required"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
import mixin from "../../../mixin";
export default {
  name: "config-layerselect",
  mixins: [mixin()],
  inject: ["formDesign"],
  props: ["data"],
  data() {
    return {
      alignOptions: [
        { value: "left", label: "靠左" },
        { value: "center", label: "居中" },
        { value: "right", label: "靠右" },
      ],
      apiArr: [], //接口列表
      pickDataArr: [], //选择的数据列表
      apiNameList: [], //字段列表
      apiColumns: [
        { label: "数据名称", prop: "f_Name", minWidth: "110" },
        { label: "数据连接", prop: "f_Url", minWidth: "110" },
      ],
      options: [],
      NameList: [],
    };
  },
  created() {
    this.lr_loadDataItemClassifys && this.lr_loadDataItemClassifys();
    this.lr_loadDataSourceList && this.lr_loadDataSourceList();
    this.loadDataSourceCol();

    // this.apiInit(); //接口模式
    // this.data.optionList = []; //弹窗组件-解决自动第二次响应 ，this.pickDataArr值丢失问题
  },
  computed: {
    layerSelectMyColNameList() {
      if (this.data.dataType == 4) {
        return this.apiNameList;
      } else {
        return this.myColNameList;
      }
    },
    dataItemClassifysTree() {
      if (this.lr_dataItemClassifysTree) {
        return this.lr_dataItemClassifysTree;
      } else {
        return [];
      }
    },
    dataSource() {
      if (this.lr_dataSource) {
        return this.lr_dataSource;
      } else {
        return [];
      }
    },
    valueOptions() {
      const res = [];
      const formInfo = this.formDesign.toSaveData();
      if (!this.data.subfield) {
        formInfo.tabList.forEach((tab) => {
          tab.components.forEach((com) => {
            if (!["divider", "gridtable", "card", "btn"].includes(com.type) && com.prop != this.data.prop) {
              res.push({ value: com.prop, label: com.label, field: com.field, table: com.table });
            }
          });
        });
      } else {
        formInfo.tabList.forEach((tab) => {
          tab.components.forEach((com) => {
            if (["gridtable"].includes(com.type) && com.children.findIndex((t) => t.prop == this.data.prop) != -1) {
              com.children.forEach((c) => {
                if (c.prop != this.data.prop) {
                  res.push({ value: c.prop, label: c.label, field: c.field, table: c.table });
                }
              });
            }
          });
        });
      }
      
      return res;
    },
  },
  methods: {
    handleLayerSelectChange(row) {
      if (row.f_Url.endsWith("/page")) {
        this.data.dataCode = row.f_Name;
        this.data.apiCode = row.f_Id;
        this.data.apiUrl = row.f_Url;
        this.data.apiResponseType = row.f_ResponseType;
        this.loadDataSourceCol();
      } else {
        this.handleDataTypeChange();
        this.$message({
          type: "error",
          message: "请选择支持分页接口",
        });
      }
    },
    handleDataTypeChange() {
      this.data.dataCode = "";
      this.data.dataValueKey = "";
      this.data.dataLabelKey = "";
      this.data.default = "";
      this.data.columns = [];
    },
    handleAddDatas() {
      this.data.options.push({ label: "选项" + (this.data.options.length + 1), value: this.data.options.length + 1 });
    },
    handleRemoveDatas(index) {
      if (this.data.default == this.data.options[index].value) {
        this.data.default = "";
      }
      this.data.options.splice(index, 1);
    },

    handleDataItemChange() {
      this.data.default = "";
      if (!this.$validatenull(this.data.dataCode) && this.lr_loadDataItem) {
        this.lr_loadDataItem(this.data.dataCode);
      }
    },
    handleDataSourceChange() {
      this.data.default = "";
      this.data.dataValueKey = "";
      this.data.dataLabelKey = "";
      if (!this.$validatenull(this.data.dataCode) && this.lr_loadDataSourceColNames) {
        this.lr_loadDataSourceColNames(this.data.dataCode);
        this.lr_loadDataSourceData(this.data.dataCode);
      }
    },
    handleDataSourceKeyLabelChange() {
      this.data.default = "";
    },

    handleAddCol() {
      this.data.columns.push({
        label: `第${this.data.columns.length}列`,
        prop: "",
        field: "",
        valueKey: "",
        width: 100,
        align: "left",
        hidden: false,
      });
    },
    handleRemoveCol(index) {
      this.data.columns.splice(index, 1);
    },
    valueKeyChange(item, valueKey) {
      item.field = `${valueKey.table}_${valueKey.field}`;
    },
  },
};
</script>
