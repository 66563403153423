<template>
    <div>
        <el-divider>私有属性</el-divider>
        <el-form-item label="默认值">
            <l-time
                v-model="data.default"
                :format="data.format"
                :selectableRange="data.selectableRange"
                placeholder="默认时间">
            </l-time>
        </el-form-item>
        <el-form-item label="时间格式">
            <el-input v-model="data.format" ></el-input>
        </el-form-item>
        <el-form-item label="时间范围">
            <l-time
                is-range
                v-model="data.selectableRange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
            </l-time>
        </el-form-item>
        <el-form-item label="是否清空">
            <el-switch v-model="data.clearable"></el-switch>
        </el-form-item>
        <el-form-item label="是否只读">
            <el-switch v-model="data.readonly"></el-switch>
        </el-form-item>
        <el-divider>校验</el-divider>
        <el-form-item label="是否必填">
            <el-switch v-model="data.required" ></el-switch>
        </el-form-item>
    </div>
</template>

<script>
export default {
  name: "config-time",
  props: ['data'],
  data () {
    return {
        formatOptions:[
            {value:'HH:mm:ss',label:'HH:mm:ss'},
            {value:'HH:mm',label:'HH:mm'}
        ]
    }
  },
  methods: {
  }
}
</script>