<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="默认值">
      <l-date v-model="data.default" :dateType="data.dateType" :format="data.format" placeholder="默认值"> </l-date>
    </el-form-item>
    <el-form-item label="时间类型">
      <l-select v-model="data.dateType" :options="dateTypeOpions" @change="handleChange"></l-select>
    </el-form-item>
    <el-form-item label="时间格式">
      <el-input v-model="data.format"></el-input>
    </el-form-item>
    <el-form-item label="是否清空">
      <el-switch v-model="data.clearable"></el-switch>
    </el-form-item>
    <el-form-item label="是否只读">
      <el-switch v-model="data.readonly"></el-switch>
    </el-form-item>
    <el-divider>校验</el-divider>
    <el-form-item label="是否必填">
      <el-switch v-model="data.required"></el-switch>
    </el-form-item>
    <el-form-item label="是否可见">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-datetime",
  props: ["data"],
  data() {
    return {
      dateTypeOpions: [
        { value: "date", label: "日期" },
        { value: "month", label: "月" },
        { value: "year", label: "年" },
        { value: "datetime", label: "日期时间" },
      ],
    };
  },
  methods: {
    handleChange() {
      switch (this.data.dateType) {
        case "date":
          this.data.format = "yyyy-MM-dd";
          break;
        case "month":
          this.data.format = "yyyy-MM";
          break;
        case "year":
          this.data.format = "yyyy";
          break;
        case "datetime":
          this.data.format = "yyyy-MM-dd HH:mm:ss";
          break;
      }
    },
  },
};
</script>
