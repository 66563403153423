<template>
  <div>
    <el-form-item :label="$t('文案')">
        <el-input v-model="data.html"
                    :placeholder="$t('请输入文案')"></el-input>
    </el-form-item>
    <el-form-item :label="$t('文案对齐')">
        <el-radio-group v-model="data.contentPosition">
            <el-radio-button label="left">{{$t('左')}}</el-radio-button>
            <el-radio-button label="center">{{$t('中')}}</el-radio-button>
            <el-radio-button label="right">{{$t('右')}}</el-radio-button>
        </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-divider",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

