<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="文件类型">
        <l-select v-model="data.accept" :options="acceptOpions" ></l-select>
    </el-form-item>
    <el-form-item label="文件大小">
        <el-input v-model="data.maxSize" type="number" >
            <el-select v-model="data.sizeType" slot="append" placeholder="请选择" class="s-w-72" >
                <el-option label="KB" value="KB"></el-option>
                <el-option label="MB" value="MB"></el-option>
                <el-option label="GB" value="GB"></el-option>
            </el-select>
        </el-input>
    </el-form-item>
    <el-form-item label="最大上传数">
        <el-input-number v-model="data.limit"
                       controls-position="right"
            placeholder="请输入最大上传数"></el-input-number>
    </el-form-item>
    <el-form-item label="显示提示">
        <el-switch v-model="data.isTip"></el-switch>
    </el-form-item>
    <el-form-item label="是否只读">
        <el-switch v-model="data.readonly"></el-switch>
    </el-form-item>
    <el-divider>校验</el-divider>
    <el-form-item label="是否必填">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-upload",
  props: ['data'],
  data () {
    return {
        acceptOpions:[
            {value:'.xls,.xlsx',label:'Excel'},
            {value:'.doc,.docx',label:'Word'},
            {value:'.pdf',label:'Pdf'},
            {value:'.txt',label:'txt'},
            {value:'image/*',label:'图片'},
            {value:'video/*',label:'视频'},
            {value:'audio/*',label:'音频'}
        ],
        sizeTypeOpions:[]
    }
  },
  methods: {

  }
}
</script>

