export default function () {
  return {
    computed: {
      dataCode() {
        return this.data.dataCode;
      },
      dataType() {
        return this.data.dataType;
      },

      myOptions() {
        // 1 静态数据 2 数据字典 3 远端数据(数据源) 4接口模式
        switch (this.dataType) {
          case "1":
            return this.data.options;
          case "4":
            if (this.lr_apiDataSourceData && this.dataCode && this.data.dataValueKey && this.data.dataLabelKey) {
              if (this.data.type == "layerselect") {
                return [];
              }

              let dataSource = [];
              if (this.data.upCtrl) {
                if (this.formData && this.formData[this.data.upCtrl]) {
                  const upCtrlVal = this.formData[this.data.upCtrl];
                  this.lr_loadApiDataSourceDataByParamter(
                    this.data.apiCode,
                    this.data.apiUrl,
                    this.data.upCtrlName,
                    upCtrlVal
                  );

                  dataSource = this.lr_apiDataSourceDataByParamter[`${this.data.apiCode}_${upCtrlVal}`] || [];
                }
              } else {
                dataSource = this.lr_apiDataSourceData[this.data.apiCode];
              }

              if (this.data.type == "treeselect") {
                if (this.data.dataIdKey && this.data.dataPIdKey) {
                  return this.lr_DataSourceTree(
                    dataSource,
                    this.data.dataIdKey,
                    this.data.dataPIdKey,
                    this.data.dataValueKey,
                    this.data.dataLabelKey
                  );
                } else {
                  return [];
                }
              } else {
                return this.lr_DataSourceOptions(dataSource, this.data.dataValueKey, this.data.dataLabelKey);
              }
            } else {
              return [];
            }

          case "2":
            if (this.lr_dataItem && this.dataCode) {
              if (this.data.type == "treeselect") {
                return this.lr_dataItemTree(this.lr_dataItem[this.dataCode]);
              } else {
                return this.lr_dataItemOptions(this.lr_dataItem[this.dataCode]);
              }
            } else {
              return [];
            }
          case "3":
            if (this.lr_dataSourceData && this.dataCode && this.data.dataValueKey && this.data.dataLabelKey) {
              let dataSource = [];
              if (this.data.upCtrl) {
                if (this.formData && this.formData[this.data.upCtrl]) {
                  const upCtrlVal = this.formData[this.data.upCtrl];
                  const upCtrlValList = upCtrlVal.split(","); // 考虑数据多选的问题
                  upCtrlValList.forEach((v) => {
                    this.lr_loadDataSourceDataByParamter(this.dataCode, v); //解决编辑赋值没有触发问题
                    const vList = this.lr_dataSourceDataByParamter[`${this.dataCode}_${v}`] || [];
                    dataSource.push(
                      ...vList.filter(
                        (t) => !dataSource.some((t2) => t2[this.data.dataValueKey] === t[this.data.dataValueKey])
                      )
                    );
                  });
                }
              } else {
                dataSource = this.lr_dataSourceData[this.dataCode];
              }

              if (this.data.type == "treeselect") {
                if (this.data.dataIdKey && this.data.dataPIdKey) {
                  return this.lr_DataSourceTree(
                    dataSource,
                    this.data.dataIdKey,
                    this.data.dataPIdKey,
                    this.data.dataValueKey,
                    this.data.dataLabelKey
                  );
                } else {
                  return [];
                }
              } else {
                return this.lr_DataSourceOptions(dataSource, this.data.dataValueKey, this.data.dataLabelKey);
              }
            } else {
              return [];
            }
          default:
            return [];
        }
      },
      myColNameList() {
        if (this.lr_dataSourceCol && this.dataCode) {
          const colNameList = this.lr_dataSourceCol[this.dataCode] || [];
          return colNameList.map((t) => {
            return { value: t, label: t };
          });
        } else {
          return [];
        }
      },
      componentOptions() {
        const res = [];
        if (this.formDesign) {
          const formInfo = this.formDesign.toSaveData();
          if (!this.data.subfield) {
            formInfo.tabList.forEach((tab) => {
              tab.components.forEach((com) => {
                if (
                  [
                    "checkbox",
                    "radio",
                    "select",
                    "selectMultiple",
                    "treeselect",
                    "layerselect",
                    "companySelect",
                    "departmentSelect",
                    "userSelect",
                  ].includes(com.type) &&
                  com.prop != this.data.prop
                ) {
                  res.push({ value: com.prop, label: com.label });
                }
              });
            });
          } else {
            formInfo.tabList.forEach((tab) => {
              tab.components.forEach((com) => {
                if (["gridtable"].includes(com.type) && com.children.findIndex((t) => t.prop == this.data.prop) != -1) {
                  com.children.forEach((c) => {
                    if (
                      [
                        "checkbox",
                        "radio",
                        "select",
                        "selectMultiple",
                        "treeselect",
                        "layerselect",
                        "companySelect",
                        "departmentSelect",
                        "userSelect",
                      ].includes(c.type) &&
                      c.prop != this.data.prop &&
                      c.prop != this.data.prop
                    ) {
                      res.push({ value: c.prop, label: c.label });
                    }
                  });
                }
              });
            });
          }
        }
        return res;
      },
    },
    created() {
      this.loadOptions();
    },
    methods: {
      componentOptionsChange(opt) {
        const dataValueKeys = this.formDesign.formInfo.tabList.flatMap((item) =>
          item.components.map((item2) => ({
            value: item2.prop,
            label: item2.dataValueKey,
          }))
        );
        const prop = dataValueKeys.find((item) => item.value == opt.value);
        this.data.upCtrlName = prop ? prop.label : "";
      },
      //api接口部分代码
      handleApiChange(row) {
        this.data.dataCode = row.f_Name;
        this.data.apiCode = row.f_Id;
        this.data.apiUrl = row.f_Url;
        this.data.apiResponseType = row.f_ResponseType;
        this.apiRequest(row.f_Id, row.f_Url);
        this.loadDataSourceCol();
      },

      loadOptions() {
        if (this.dataType == "4" && this.data.apiUrl && this.dataCode) {
          if (this.data.type == "layerselect") {
            return;
          }

          if (this.data.upCtrl) {
            if (this.formData && this.formData[this.data.upCtrl]) {
              const upCtrlVal = this.formData[this.data.upCtrl];
              this.lr_loadApiDataSourceDataByParamter(
                this.data.apiCode,
                this.data.apiUrl,
                this.data.upCtrlName,
                upCtrlVal
              );
            }
          } else {
            this.lr_loadApiDataSourceData(this.data.apiCode, this.data.apiUrl);
          }
        } else if (this.dataType == "2" && this.lr_loadDataItem && this.dataCode) {
          this.lr_loadDataItem(this.dataCode);
        } else if (this.dataType == "3" && this.lr_loadDataSourceData && this.dataCode) {
          if (this.data.upCtrl) {
            //add by torres 20210813
            //新写 用this.formData 获取upCtrl的值
            if (this.formData && this.formData[this.data.upCtrl]) {
              //update by cbb
              const upCtrlVal = this.formData[this.data.upCtrl];
              this.lr_loadDataSourceDataByParamter(this.dataCode, upCtrlVal);
            }
          } else {
            this.lr_loadDataSourceData(this.dataCode);
          }
        }
      },
      loadDataSourceCol() {
        if (this.dataType == "3" && this.lr_loadDataSourceData && this.dataCode) {
          this.lr_loadDataSourceColNames(this.dataCode);
        } else if (this.dataType == "4" && this.lr_loadApiDataSourceData && this.dataCode) {
          //解析接口的数据结构
          if (this.data.apiResponseType) {
            this.apiNameList = this.turnPropertysInfo(JSON.parse(this.data.apiResponseType)).map((item) => ({
              label: item,
              value: item,
            }));
          }
        }
      },
      getComponent(type) {
        let result = "el-input";
        switch (type) {
          case "divider":
            result = "el-divider";
            break;
          case "input":
          case "textarea":
          case "password":
            result = "el-input";
            break;
          case "number":
            result = "el-input-number";
            break;
          case "radio":
            result = "l-radio";
            break;
          case "checkbox":
            result = "l-checkbox";
            break;
          case "select":
          case "selectMultiple":
            result = "l-select";
            break;
          case "cascader":
            result = "el-cascader";
            break;
          case "treeselect":
            result = "l-tree-select";
            break;
          case "time":
            result = "l-time";
            break;
          case "timerange":
            result = "l-time";
            break;
          case "datetime":
            result = "l-date";
            break;
          case "datetimerange":
            result = "l-date";
            break;
          case "upload":
            result = "l-upload";
            break;
          case "uploadimg":
            result = "l-upload";
            break;
          case "guid":
            result = "l-guid";
            break;
          case "companySelect":
            result = "l-company-select";
            break;
          case "departmentSelect":
            result = "l-department-select";
            break;
          case "userSelect":
            result = "l-user-select";
            break;
          case "company":
            result = "l-company";
            break;
          case "department":
            result = "l-department";
            break;
          case "createuser":
            result = "l-createuser";
            break;
          case "modifyuser":
            result = "l-modifyuser";
            break;
          case "createtime":
            result = "l-createtime";
            break;
          case "modifytime":
            result = "l-modifytime";
            break;
          case "encode":
            result = "l-code";
            break;
          case "icon":
            result = "l-input-icon";
            break;
          case "rate":
            result = "el-rate";
            break;
          case "switch":
            result = "el-switch";
            break;
          case "slider":
            result = "el-slider";
            break;
          case "color":
            result = "l-input-color";
            break;
          case "areaselect":
            result = "l-area-select";
            break;
          case "layerselect":
            result = "l-layer-select";
            break;
          case "layerbmap":
            result = "l-BMap-select";
            break;
          case "backfill":
            result = "l-backfill";
            break;
        }
        return result;
      },
      //分页
      loadPageData(pageData) {
        return new Promise((resovle) => {
          this.lr_apiListData(pageData).then((res) => {
            this.apiArr = this.$deepClone(this.lr_dataSourceApi["api"]);
            resovle(res);
          });
        });
      },

      turnPropertysInfo(responseType) {
        let mainDbTables = [];
        let childDbTables = [];
        if (responseType.propertysInfo) {
          const mainData = responseType.propertysInfo.filter((item) => !item.propertysInfo.length);
          if (mainData.length) {
            //说明对象包含基本类型数据
            mainDbTables = mainData.map((item) => {
              return item.propertyName.toLowerCase();
            });
          }
          const childData = responseType.propertysInfo.filter((item) => item.propertysInfo.length);
          childDbTables = childData.flatMap((item) => {
            return item.propertysInfo.map((info) => {
              return info.propertyName.toLowerCase();
            });
          });
        }
        return mainDbTables.concat(childDbTables);
      },
      turnDataSourcetoLowerCase(data) {
        if (Array.isArray(data)) {
          return data.map((item) => {
            if (Object.prototype.toString.call(item) == "[object Object]") {
              return Object.keys(item).reduce((acc, cur) => {
                acc[cur.toLowerCase()] = item[cur];
                return acc;
              }, {});
            }
            return item;
          });
        }
        return data;
      },
      //接口模式-接口请求
      apiRequest(apiCode, apiUrl) {
        this.lr_loadApiDataSourceData(apiCode, apiUrl);
      },
    },
  };
}
