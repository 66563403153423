<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="默认值">
      <el-slider v-bind="$deepClone(data)" v-model="data.default"> </el-slider>
    </el-form-item>

    <el-form-item label="最小值">
      <el-input-number v-model="data.min" controls-position="right" placeholder="请输入最小值"></el-input-number>
    </el-form-item>
    <el-form-item label="最大值">
      <el-input-number v-model="data.max" controls-position="right" placeholder="请输入最大值"></el-input-number>
    </el-form-item>
    <el-form-item label="步长">
      <el-input-number v-model="data.step" controls-position="right" placeholder="请输入步长"></el-input-number>
    </el-form-item>

    <el-form-item label="输入框">
      <el-switch v-model="data.showInput"></el-switch>
    </el-form-item>
    <el-form-item label="输入框按钮">
      <el-switch v-model="data.showInputControls"></el-switch>
    </el-form-item>
    <el-form-item label="显示间断点">
      <el-switch v-model="data.showStops"></el-switch>
    </el-form-item>
    <el-form-item label="显示tooltip">
      <el-switch v-model="data.showTooltip"></el-switch>
    </el-form-item>

    <el-form-item label="是否可见">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
    <el-form-item label="是否只读">
      <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-slider",
  props: ["data"],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
