<template>
  <div>
    <el-form-item label="默认值">
      <l-tree-select :key="data.key" v-model="data.default" placeholder="请选择默认值" size="mini" :options="myOptions">
      </l-tree-select>
    </el-form-item>
    <el-divider>选项</el-divider>
    <div style="text-align: center; margin-bottom: 16px">
      <el-radio-group v-model="data.dataType" size="mini" @change="handleDataTypeChange">
        <el-radio-button label="1">静态数据</el-radio-button>
        <el-radio-button label="2">数据字典</el-radio-button>
        <el-radio-button label="3">数据源</el-radio-button>
        <el-radio-button label="4" v-if="formDesign.formType == 2">接口</el-radio-button>
      </el-radio-group>
    </div>
    <!--静态数据-->
    <div v-if="data.dataType == 1">
      <el-tree
        ref="tree"
        :data="data.options"
        default-expand-all
        draggable
        node-key="value"
        :expand-on-click-node="false"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button type="text" size="mini" icon="el-icon-plus" @click="handleNodeAdd(data)"></el-button>
            <!--                <el-button class="warning" type="text" size="mini" icon="el-icon-edit"-->
            <!--                           @click="handleNodeEdit(data)"></el-button>-->
            <el-button
              class="danger"
              type="text"
              size="mini"
              icon="el-icon-delete"
              @click="handleNodeRemove(node, data)"
            ></el-button>
          </span>
        </span>
      </el-tree>
      <div style="margin-left: 22px">
        <el-button size="mini" type="text" icon="el-icon-circle-plus-outline" @click="handleParentNodeAdd"
          >添加父级</el-button
        >
      </div>
    </div>
    <!--数据字典-->
    <div v-else-if="data.dataType == 2">
      <l-tree-select
        v-model="data.dataCode"
        :options="dataItemClassifysTree"
        placeholder="请选择数据字典"
        size="mini"
        :parent="false"
        @change="handleDataItemChange"
      >
      </l-tree-select>
    </div>
    <!--接口模式-->
    <div v-else-if="data.dataType == 4">
      <el-form-item label="选择接口">
        <l-layer-select
          v-model="data.dataCode"
          labelKey="f_Name"
          valueKey="f_Id"
          :columns="apiColumns"
          :options="apiArr"
          @change="handleApiChange"
          :loadPageData="loadPageData"
        ></l-layer-select>
      </el-form-item>
      <el-form-item label="选项值">
        <l-select
          v-model="data.dataValueKey"
          placeholder="请选择选项值"
          size="mini"
          :options="apiNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="选项名">
        <l-select
          v-model="data.dataLabelKey"
          placeholder="请选择选项名"
          size="mini"
          :options="apiNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="id字段">
        <l-select
          v-model="data.dataIdKey"
          placeholder="请选择选项id字段"
          size="mini"
          :options="apiNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="pid字段">
        <l-select
          v-model="data.dataPIdKey"
          placeholder="请选择选项父级id字段"
          size="mini"
          :options="apiNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="上一级">
        <l-select
          v-model="data.upCtrl"
          placeholder="请选择"
          size="mini"
          @change="componentOptionsChange"
          :options="componentOptions"
        >
        </l-select>
      </el-form-item>
    </div>
    <!--远端数据-->
    <div v-else>
      <el-form-item label="数据源">
        <l-select
          v-model="data.dataCode"
          placeholder="请选择数据源"
          size="mini"
          :options="dataSource"
          labelKey="f_Name"
          valueKey="f_Code"
          @change="handleDataSourceChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="值字段">
        <l-select
          v-model="data.dataValueKey"
          placeholder="请选择选项值字段"
          size="mini"
          :options="myColNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="名字段">
        <l-select
          v-model="data.dataLabelKey"
          placeholder="请选择选项名字段"
          size="mini"
          :options="myColNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="id字段">
        <l-select
          v-model="data.dataIdKey"
          placeholder="请选择选项id字段"
          size="mini"
          :options="myColNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item label="pid字段">
        <l-select
          v-model="data.dataPIdKey"
          placeholder="请选择选项父级id字段"
          size="mini"
          :options="myColNameList"
          @change="handleDataSourceKeyLabelChange"
        >
        </l-select>
      </el-form-item>

      <el-form-item label="上一级">
        <l-select v-model="data.upCtrl" placeholder="请选择" size="mini" :options="componentOptions"> </l-select>
      </el-form-item>
    </div>

    <el-divider></el-divider>
    <el-form-item label="是否只读">
      <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item label="是否必填">
      <el-switch v-model="data.required"></el-switch>
    </el-form-item>

    <l-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :height="200"
      @close="closeDialog"
      @ok="handleDialogAdd"
    >
      <div class="l-from-body">
        <el-form :model="dialogForm" size="mini" :rules="dialogRules" ref="dialogForm" label-width="80px">
          <el-form-item label="选项名" prop="label">
            <el-input v-model="dialogForm.label"></el-input>
          </el-form-item>
          <el-form-item label="选项值" prop="value">
            <el-input v-model="dialogForm.value"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </l-dialog>
  </div>
</template>

<script>
import mixin from "../../../mixin";
//import postData from '../../postData.js';
export default {
  name: "config-treeselect",
  mixins: [mixin()],
  inject: ["formDesign"],
  props: ["data"],
  data() {
    return {
      dialogTitle: "添加选项",
      dialogVisible: false,
      dialogForm: {},
      dialogRules: {
        label: { required: true, message: "请输入选项名", trigger: "null" },
        value: { required: true, message: "请输入选项值", trigger: "null" },
      },
      pData: undefined,

      //接口模式
      apiArr: [],
      apiNameList: [],
      apiColumns: [
        { label: "数据名称", prop: "f_Name", minWidth: "110" },
        { label: "数据连接", prop: "f_Url", minWidth: "110" },
      ],
      options: [
        {
          label: "选项一",
          value: "0",
          children: [
            {
              label: "选项1-1",
              value: "11",
            },
            {
              label: "选项1-2",
              value: "12",
            },
          ],
        },
        {
          label: "选项二",
          value: "1",
        },
        {
          label: "选项三",
          value: "2",
        },
      ],
    };
  },
  created() {
    this.lr_loadDataItemClassifys && this.lr_loadDataItemClassifys();
    this.lr_loadDataSourceList && this.lr_loadDataSourceList();
    this.loadDataSourceCol();

    //this.apiInit(); //接口模式
  },
  computed: {
    dataItemClassifysTree() {
      if (this.lr_dataItemClassifysTree) {
        return this.lr_dataItemClassifysTree;
      } else {
        return [];
      }
    },
    dataSource() {
      if (this.lr_dataSource) {
        return this.lr_dataSource;
      } else {
        return [];
      }
    },
  },
  methods: {
    handleDataTypeChange() {
      this.data.dataCode = "";
      this.data.dataValueKey = "";
      this.data.dataLabelKey = "";
      this.data.default = "";
    },

    handleParentNodeAdd() {
      this.pData = undefined;
      this.dialogTitle = "添加父级选项";
      this.dialogVisible = true;
    },
    handleNodeAdd(data) {
      this.pData = data;
      this.dialogTitle = `添加【${data.label}】的子选项`;
      this.dialogVisible = true;
    },
    handleNodeRemove(node, data) {
      this.data.default = "";
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },

    handleDialogAdd() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          const { label, value } = this.dialogForm;
          const node = this.$refs.tree.getNode(value);
          if (node) this.$message.error("选项值重复");
          else {
            const pData = this.pData;
            const newNode = {
              label,
              value: this.dialogInputType == "number" ? Number(value) : value,
            };
            if (pData) {
              if (!pData.children) this.$set(pData, "children", []);
              pData.children.push(newNode);
            } else {
              this.$set(this.data.options, this.data.options.length, newNode);
            }
            this.dialogVisible = false;
          }
        }
      });
    },
    closeDialog() {
      this.$refs.dialogForm.clearValidate();
      this.dialogForm = {};
    },

    handleDataItemChange() {
      this.data.default = "";
      if (!this.$validatenull(this.data.dataCode) && this.lr_loadDataItem) {
        this.lr_loadDataItem(this.data.dataCode);
      }
    },
    handleDataSourceChange() {
      this.data.default = "";
      this.data.dataValueKey = "";
      this.data.dataLabelKey = "";
      this.data.dataIdKey = "";
      this.data.dataPIdKey = "";
      if (!this.$validatenull(this.data.dataCode) && this.lr_loadDataSourceColNames) {
        this.lr_loadDataSourceData(this.data.dataCode);
        this.lr_loadDataSourceColNames(this.data.dataCode);
      }
    },
    handleDataSourceKeyLabelChange() {
      this.data.default = "";

      //this.apiTreeChange(); //接口模式
    },
  },
};
</script>
