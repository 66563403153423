var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-layout',{staticClass:"l-form-design",attrs:{"leftMove":false,"rightMove":false,"left":264,"right":344},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('l-panel',{style:({
        'padding-right': 0,
        'padding-top': _vm.isNotTopPadding ? 0 : undefined,
        'padding-bottom': _vm.isNotBottomPadding ? 0 : undefined,
      })},_vm._l((_vm.myComponents),function(myComponent,index){return _c('div',{key:index},[_c('div',{staticClass:"l-form-design--myComponent-title"},[_vm._v(_vm._s(_vm.$t(myComponent.title)))]),_c('draggable',{staticClass:"myComponent-item-draggable",attrs:{"list":myComponent.list,"group":{ name: 'form', pull: 'clone', put: false },"ghost-class":"ghost","sort":false}},_vm._l((myComponent.list),function(item,index){return _c('div',{key:index,staticClass:"myComponent-item",on:{"click":function($event){return _vm.handleClick(item)}}},[_c('div',{staticClass:"myComponent-item-body"},[_c('i',{staticClass:"myComponent-item-icon",class:item.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(item.title || item.label)))])])])}),0)],1)}),0)]},proxy:true},{key:"right",fn:function(){return [_c('l-panel',{style:({
        'padding-left': 0,
        'padding-top': _vm.isNotTopPadding ? 0 : undefined,
        'padding-bottom': _vm.isNotBottomPadding ? 0 : undefined,
      })},[_c('div',{staticClass:"l-auto-window"},[_c('el-tabs',{attrs:{"stretch":true},model:{value:(_vm.configActiveName),callback:function ($$v) {_vm.configActiveName=$$v},expression:"configActiveName"}},[_c('el-tab-pane',{attrs:{"label":`${_vm.$t('组件属性')}${_vm.componentName}`,"name":"tab01"}},[_c('component-config',{ref:"componentConfig",attrs:{"data":_vm.dragFormSelect}})],1),_c('el-tab-pane',{attrs:{"label":_vm.$t('表单属性'),"name":"tab02"}},[_c('form-config')],1)],1)],1)])]},proxy:true}])},[_c('l-panel',{style:({
      'padding-left': 0,
      'padding-right': 0,
      'padding-top': _vm.isNotTopPadding ? 0 : undefined,
      'padding-bottom': _vm.isNotBottomPadding ? 0 : undefined,
    }),scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger","icon":"el-icon-delete"},on:{"click":_vm.handleClear}},[_vm._v("清空")]),_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary","icon":"el-icon-video-play"},on:{"click":_vm.handleViewer}},[_vm._v("预览")])]},proxy:true}])},[_c('div',{staticClass:"l-rblock",staticStyle:{"padding":"11px"}},[_c('div',{class:['l-auto-window', { 'only-tabs': _vm.formInfo.tabList.length == 1 }]},[_c('el-tabs',{attrs:{"type":"card"},model:{value:(_vm.formActiveName),callback:function ($$v) {_vm.formActiveName=$$v},expression:"formActiveName"}},_vm._l((_vm.formInfo.tabList),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.text,"name":'tab' + index}},[_c('div',{staticClass:"l-rblock",style:({ background: item.components.length == 0 ? `url(${_vm.imgUrl}) no-repeat 50%` : '' })},[_c('drag-form',{ref:"dragForm",refInFor:true,attrs:{"myTabsIndex":index,"select":_vm.dragFormSelect},on:{"update:select":function($event){_vm.dragFormSelect=$event}}})],1)])}),1)],1)])]),(_vm.formInfo.openType != '2')?_c('l-dialog',{attrs:{"title":_vm.$t('表单预览'),"visible":_vm.viewerVisible,"height":_vm.formInfo.dialogHeight || 600,"width":_vm.formInfo.dialogWidth || 800},on:{"update:visible":function($event){_vm.viewerVisible=$event},"opened":_vm.handleFormOpened,"ok":_vm.handleCloseViewer}},[_c('l-form-viewer',{ref:"formViewer",attrs:{"formInfo":_vm.viewerFormInfo}})],1):_c('l-drawer',{attrs:{"title":_vm.$t('表单预览'),"visible":_vm.viewerVisible,"width":_vm.formInfo.drawerWidth || 600},on:{"update:visible":function($event){_vm.viewerVisible=$event},"opened":_vm.handleFormOpened,"ok":_vm.handleCloseViewer}},[_c('l-form-viewer',{ref:"formViewer",attrs:{"formInfo":_vm.viewerFormInfo}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }