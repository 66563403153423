<template>
  <div>
    <el-divider>数据</el-divider>
    <div style="text-align: center; margin-bottom: 16px">
      <el-radio-group v-model="data.dataType" size="mini" @change="handleDataTypeChange">
        <el-radio-button label="3">数据源</el-radio-button>
        <el-radio-button label="4" v-if="formDesign.formType == 2">接口</el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="data.dataType == 4">
      <!--接口数据-->
      <el-form-item label="选择接口">
        <l-layer-select
          v-model="data.dataCode"
          labelKey="f_Name"
          valueKey="f_Id"
          :columns="apiColumns"
          :options="apiArr"
          @change="handleApiDataSourceChange"
          :loadPageData="loadPageData"
        ></l-layer-select>
      </el-form-item>
    </div>
    <div v-else>
      <!--远端数据-->
      <el-form-item label="数据源">
        <l-select
          v-model="data.dataCode"
          placeholder="请选择数据源"
          size="mini"
          :options="dataSource"
          labelKey="f_Name"
          valueKey="f_Code"
          @change="handleDataSourceChange"
        >
        </l-select>
      </el-form-item>
    </div>
    <el-form-item label="关联字段">
      <l-select
        v-model="data.paramFiled"
        placeholder="请选择"
        size="mini"
        :options="componentParamOptions"
        @change="componentParamOptionsChange"
      >
      </l-select>
    </el-form-item>
    <el-form-item label="是否分页">
      <el-switch v-model="data.isPage"></el-switch>
    </el-form-item>

    <el-divider>数据列</el-divider>
    <div class="reg-item" :key="index" v-for="(item, index) in data.columns">
      <el-form-item label="列名" style="margin-bottom: 8px">
        <el-input v-model="item.label" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="字段" style="margin-bottom: 8px">
        <l-select v-model="item.prop" :options="myViewTableOptions"></l-select>
      </el-form-item>
      <el-form-item label="宽度" style="margin-bottom: 8px">
        <el-input v-model="item.width" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="对齐" style="margin-bottom: 0">
        <l-select v-model="item.align" :options="alignOptions"></l-select>
      </el-form-item>

      <el-button
        title="删除"
        @click="handleRemoveCol(index)"
        class="reg-item-delete"
        circle
        plain
        size="mini"
        type="danger"
      >
        <i class="el-icon-close"></i>
      </el-button>
    </div>
    <div class="mt-8">
      <el-button class="ml-8" type="primary" size="mini" @click="handleAddCol">添加列</el-button>
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixin";
export default {
  name: "config-viewtable",
  mixins: [mixin()],
  inject: ["formDesign"],
  props: ["data"],
  data() {
    return {
      alignOptions: [
        { value: "left", label: "靠左" },
        { value: "center", label: "居中" },
        { value: "right", label: "靠右" },
      ],
      apiColumns: [
        { label: "数据名称", prop: "f_Name", minWidth: "110" },
        { label: "数据连接", prop: "f_Url", minWidth: "110" },
      ],
      apiArr: [],
      feild: "",
      apiNameList: [],
    };
  },

  created() {
    this.lr_loadDataSourceList && this.lr_loadDataSourceList();
    this.loadDataSourceCol();
  },
  computed: {
    myViewTableOptions() {
      if (this.data.dataType == 4) {
        return this.apiNameList;
      } else {
        return this.myColNameList;
      }
    },
    dataSource() {
      if (this.lr_dataSource) {
        return this.lr_dataSource;
      } else {
        return [];
      }
    },
    componentParamOptions() {
      const res = [];
      if (this.formDesign) {
        const formInfo = this.formDesign.toSaveData();
        formInfo.tabList.forEach((tab) => {
          tab.components.forEach((com) => {
            if (
              !["gridtable", "divider", "viewtable", "card", "btn", "backfill"].includes(com.type) &&
              com.prop != this.data.prop
            ) {
              res.push({ value: com.prop, label: com.label, field: com.field });
            }
          });
        });
      }
      return res;
    },
  },
  methods: {
    componentParamOptionsChange(row) {
      const param = this.componentParamOptions.find((item) => item.value == row.value);
      this.data.apiField = param.field;
    },
    handleApiDataSourceChange(row) {
      this.data.dataCode = row.f_Name;
      this.data.apiResponseType = row.f_ResponseType;
      this.data.apiCode = row.f_Id;
      this.data.apiUrl = row.f_Url;
      this.data.apiMethod = row.f_RequestMethod;
      this.loadDataSourceCol();
    },
    handleDataTypeChange() {
      this.data.dataCode = "";
      this.data.dataValueKey = "";
      this.data.dataLabelKey = "";
      this.data.default = "";
    },
    handleDataSourceChange() {
      if (!this.$validatenull(this.data.dataCode) && this.lr_loadDataSourceColNames) {
        this.lr_loadDataSourceColNames(this.data.dataCode);
        this.lr_loadDataSourceData(this.data.dataCode);
      }
    },
    handleAddCol() {
      this.data.columns.push({
        label: `第${this.data.columns.length}列`,
        prop: "",
        width: 100,
        align: "left",
        hidden: false,
      });
    },
    handleRemoveCol(index) {
      this.data.columns.splice(index, 1);
    },
  },
};
</script>
