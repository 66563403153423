<template>
  <div class="drag-form-container l-tabs-container">
    <el-form
      :label-position="formInfo.labelPosition || 'left'"
      :label-width="formInfo.labelWidth ? `${formInfo.labelWidth}px` : '80px'"
      :size="formInfo.size || 'mini'"
      :model="form"
    >
      <input type="text" style="display: none" />
      <el-row>
        <draggable
          :class="['drag-form-list']"
          :list="formComponents"
          :group="{ name: 'form' }"
          ghost-class="ghost"
          :animation="300"
          @add="handleFormAdd"
        >
          <template v-for="(column, index) in formComponents">
            <div
              class="drag-form-table"
              v-if="column.type == 'gridtable'"
              :key="column.prop"
              :class="{ active: selectWidget.prop == column.prop }"
              @click="handleSelectWidget(index)"
            >
              <div class="drag-form-table__title">{{ $t(column.label) }}</div>
              <drag-form-table
                :tableComponent="column"
                :index="index"
                :select.sync="selectWidget"
                :plist="formComponents"
                :ref="column.prop"
                @change="$emit('change')"
              ></drag-form-table>
            </div>
            <div
              class="drag-form-table viewtable"
              v-else-if="column.type == 'viewtable'"
              :key="column.prop"
              :class="{ active: selectWidget.prop == column.prop }"
              @click="handleSelectWidget(index)"
            >
              <div class="drag-form-table__title">{{ $t(column.label) }}</div>
              <l-table :columns="column.columns"></l-table>
              <el-button
                title="复制"
                @click.stop="handleWidgetClone(index)"
                class="drag-action-clone"
                v-if="selectWidget.prop == column.prop"
                circle
                plain
                size="mini"
                type="primary"
              >
                <i class="el-icon-copy-document"></i>
              </el-button>
              <el-button
                title="删除"
                @click.stop="handleWidgetDelete(index)"
                class="drag-action-delete"
                v-if="selectWidget.prop == column.prop"
                circle
                plain
                size="mini"
                type="danger"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </div>
            <el-col v-else :key="column.prop" :md="column.span || 12" :xs="24" :offset="column.offset || 0">
              <div
                class="drag-form-table"
                v-if="column.type == 'card'"
                :key="column.prop"
                :class="{ active: selectWidget.prop == column.prop }"
                @click="handleSelectWidget(index)"
              >
                <div class="drag-form-table__title">{{ $t(column.label) }}</div>
                <drag-form-card
                  :tableComponent="column"
                  :index="index"
                  :plist="formComponents"
                  :select.sync="selectWidget"
                  :ref="column.prop"
                  @change="$emit('change')"
                >
                </drag-form-card>
              </div>
              <div
                class="drag-form-table drag-form-btn"
                v-else-if="column.type == 'backfill'"
                :key="column.prop"
                :class="{ active: selectWidget.prop == column.prop }"
                @click="handleSelectWidget(index)"
              >
                <l-backfill v-bind="column"></l-backfill>

                <el-button
                  title="复制"
                  @click.stop="handleWidgetClone(index)"
                  class="drag-action-clone"
                  v-if="selectWidget.prop == column.prop"
                  circle
                  plain
                  size="mini"
                  type="primary"
                >
                  <i class="el-icon-copy-document"></i>
                </el-button>
                <el-button
                  title="删除"
                  @click.stop="handleWidgetDelete(index)"
                  class="drag-action-delete"
                  v-if="selectWidget.prop == column.prop"
                  circle
                  plain
                  size="mini"
                  type="danger"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </div>

              <div
                class="drag-form-table drag-form-btn"
                v-else-if="column.type == 'btn'"
                :key="column.prop"
                :class="{ active: selectWidget.prop == column.prop }"
                @click="handleSelectWidget(index)"
              >
                <el-button
                  :size="column.size"
                  :plain="column.plain"
                  :round="column.round"
                  :circle="column.circle"
                  :icon="column.myIcon"
                  :type="column.myType"
                  >{{ column.label }}</el-button
                >
                <el-button
                  title="复制"
                  @click.stop="handleWidgetClone(index)"
                  class="drag-action-clone"
                  v-if="selectWidget.prop == column.prop"
                  circle
                  plain
                  size="mini"
                  type="primary"
                >
                  <i class="el-icon-copy-document"></i>
                </el-button>
                <el-button
                  title="删除"
                  @click.stop="handleWidgetDelete(index)"
                  class="drag-action-delete"
                  v-if="selectWidget.prop == column.prop"
                  circle
                  plain
                  size="mini"
                  type="danger"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </div>

              <el-form-item
                v-else
                class="widget-form-item"
                :label="column.type != 'divider' ? column.label : ''"
                :labelWidth="column.labelWidth == undefined ? undefined : column.labelWidth + 'px'"
                :prop="column.prop"
                :required="column.required"
                :class="{ active: selectWidget.prop == column.prop, required: column.required }"
                :show-message="false"
                @click.native="handleSelectWidget(index)"
              >
                <drag-form-item :data="column"></drag-form-item>
                <el-button
                  title="复制"
                  @click.stop="handleWidgetClone(index)"
                  class="drag-action-clone"
                  v-if="selectWidget.prop == column.prop"
                  circle
                  plain
                  size="mini"
                  type="primary"
                >
                  <i class="el-icon-copy-document"></i>
                </el-button>
                <el-button
                  title="删除"
                  @click.stop="handleWidgetDelete(index)"
                  class="drag-action-delete"
                  v-if="selectWidget.prop == column.prop"
                  circle
                  plain
                  size="mini"
                  type="danger"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </el-form-item>
            </el-col>
          </template>
        </draggable>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import dragFormTable from "./dragFormTable";
import dragFormItem from "./dragFormItem";
import dragFormCard from "./dragFormCard.vue";

export default {
  name: "drag-form",
  components: { dragFormTable, dragFormCard, dragFormItem },
  inject: ["formDesign"],
  props: {
    select: Object,
    myTabsIndex: Number,
  },
  computed: {
    formInfo() {
      return this.formDesign.formInfo;
    },
    formComponents() {
      return this.formDesign.formInfo.tabList[this.myTabsIndex].components || [];
    },
    selectWidget: {
      get() {
        return this.select;
      },
      set(val) {
        this.$emit("update:select", val);
      },
    },
    dbTables() {
      return this.formDesign.dbTables;
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    handleSelectWidget(index) {
      this.selectWidget = this.formComponents[index];
    },
    handleGetVueComponent(refs, prop) {
      let vueComponent = refs[prop];
      if (!vueComponent) {
        for (const id in refs) {
          vueComponent = this.handleGetVueComponent(refs[id][0].$refs, prop);
          if (vueComponent) {
            break;
          }
        }
      }
      return vueComponent;
    },
    handleFormClickAdd(item) {
      // 如果当前选中的是卡片或者子表直接向卡片或子表中添加
      if (["card", "gridtable"].includes(this.selectWidget.type)) {
        this.handleGetVueComponent(this.$refs, this.selectWidget.prop)[0].handleFormClickAdd(item);
      } else {
        this.formComponents.push(item);
        this.handleFormAdd({ newIndex: this.formComponents.length - 1 });
      }
    },
    handleFormAdd(evt) {
      const newIndex = evt.newIndex;
      const data = this.$deepClone(this.formComponents[newIndex]);
      if (data.type == "guid") {
        data.default = "";
      }
      if (!data.prop) data.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      if (data.subfield) {
        data.table = "";
        data.field = "";
      }
      if (data.type == "gridtable") {
        // 如果是子表
        const tables = this.dbTables.filter((t) => t.type == "chlid");
        if (tables.length == 1) {
          data.table = tables[0].name;
        }
      } else if (!["divider", "viewtable", "card", "btn"].includes(data.type)) {
        // 如果只有一个表的时候,默认赋值
        if (this.dbTables.length == 1) {
          data.table = this.dbTables[0].name;
        }
      }

      if (data.subfield && data.type == "layerselect") {
        data.columns.forEach((col) => {
          col.valueKey = "";
        });
      }

      delete data.icon;
      delete data.subfield;

      this.$set(this.formComponents, newIndex, data);
      this.handleSelectWidget(newIndex);
      this.$emit("change");
    },
    handleWidgetDelete(index) {
      if (this.formComponents.length - 1 === index) {
        if (index === 0) this.selectWidget = {};
        else this.handleSelectWidget(index - 1);
      } else this.handleSelectWidget(index + 1);
      this.$nextTick(() => {
        this.formComponents.splice(index, 1);
        this.$emit("change");
      });
    },
    handleWidgetClone(index) {
      let cloneData = this.$deepClone(this.formComponents[index]);
      if (cloneData.type == "guid") {
        cloneData.default = "";
      }
      cloneData.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      cloneData.table = "";
      cloneData.field = "";
      this.formComponents.splice(index, 0, cloneData);
      this.$nextTick(() => {
        this.handleSelectWidget(index + 1);
        this.$emit("change");
      });
    },
  },
};
</script>
