<template>
  <div>
    <l-editor
      v-if="data.type == 'texteditor'"
      v-model="value2"
      :options="{ theme: 'snow', placeholder: $t('请输入内容...') }"
    >
    </l-editor>
    <component
      v-else
      :is="getComponent(data.type)"
      v-model="value2"
      v-bind="data"
      :placeholder="$t(data.placeholder)"
      :options="myOptions"
      :size="data.subfield ? 'mini' : data.size"
      :activeValue="toValueType(data.valueType, data.activeValue)"
      :inactiveValue="toValueType(data.valueType, data.inactiveValue)"
      :disabled="disabled"
      :formData="formData"
      @change="handleChange"
    >
      <span v-if="data.html" v-html="data.html"></span>
      <template v-if="['input'].includes(data.type) && data.prepend" #prepend>
        {{ data.prepend }}
      </template>
      <template v-if="['input'].includes(data.type) && data.append" #append>
        {{ data.append }}
      </template>
    </component>
  </div>
</template>
<script>
import mixin from "../../mixin";
export default {
  name: "viewer-form-item",
  mixins: [mixin()],
  props: {
    value: {},
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isForm: {
      // 是否表单里的对象，用来区分对查询框的操作
      type: Boolean,
      default: false,
    },
    upCtrls: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isRead: {
      // 是否表单里的对象，用来区分对查询框的操作
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value2: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    disabled() {
      if (this.isRead) {
        return true;
      }

      if (this.data.subfield) {
        const abledList = this.formData.abledList || [];
        if (this.formData.disabled && abledList.indexOf(this.data.prop) == -1) {
          return true;
        }
      }
      return this.data.disabled;
    },
  },
  methods: {
    toValueType(type, value) {
      if (!this.$validatenull(value) && !this.$validatenull(type)) {
        switch (type) {
          case "boolean":
            return value == "true";
          case "string":
            return value;
          case "number":
            return Number(value);
        }
      }
    },
    handleChange(changeData) {
      this.$nextTick(() => {
        // 1.改变级联数据，清空级联数据
        if (
          [
            "checkbox",
            "radio",
            "select",
            "selectMultiple",
            "treeselect",
            "layerselect",
            "companySelect",
            "departmentSelect",
            "userSelect",
          ].includes(this.data.type)
        ) {
          this.clearSubValue(this.data.prop);
        }
        // 根据右侧赋值字段给表单赋值地图信息
        if (this.data.type == "layerbmap") {
          if (this.data.bindaddr) {
            if (changeData) {
              this.$set(this.formData, this.data.bindaddr, changeData.address); //设置绑定地址
            } else {
              this.$set(this.formData, this.data.bindaddr, ""); //设置绑定地址
            }
          }
          if (this.data.bindaddrpoint) {
            if (changeData) {
              this.$set(this.formData, this.data.bindaddrpoint, `${changeData.lng},${changeData.lat}`); //设置绑定经纬度
            } else {
              this.$set(this.formData, this.data.bindaddrpoint, ""); //设置绑定地址
            }
          }
        }
        // 弹窗赋值
        if (this.data.type == "layerselect") {
          this.data.columns.forEach((col) => {
            if (col.valueKey) {
              if (changeData) {
                this.$set(this.formData, col.valueKey, changeData[col.prop]);
              } else {
                this.$set(this.formData, col.valueKey, undefined);
              }
            }
          });
        }
        this.$emit("change", { prop: this.data.prop, obj: changeData });
      });
    },
    clearSubValue(upProp) {
      if (this.upCtrls[upProp]) {
        this.upCtrls[upProp].forEach((prop) => {
          this.$set(this.formData, prop, "");
          this.clearSubValue(prop);
        });
      }
    },
  },
};
</script>
