<template>
  <div>
    <el-divider>私有属性</el-divider>
    <el-form-item label="默认值">
      <el-rate
        v-model="data.default"
        :max="data.max"
        :allowHalf="data.allowHalf"
        :lowThreshold="data.lowThreshold"
        :highThreshold="data.highThreshold"
        :colors="data.colors"
        :voidColor="data.voidColor"
        :disabledVoidColor="data.disabledVoidColor"
        :iconClasses="data.iconClasses"
        :voidIconClass="data.voidIconClass"
        :disabledVoidIconClass="data.disabledVoidIconClass"
        :showText="data.showText"
        :showScore="data.showScore"
        :textColor="data.textColor"
        :texts="data.texts"
      >
      </el-rate>
    </el-form-item>
    <el-form-item label="最大分值">
      <el-input-number v-model="data.max" controls-position="right" placeholder="请输入最大分值"></el-input-number>
    </el-form-item>
    <el-form-item label="低分段">
      <el-input-number
        v-model="data.lowThreshold"
        controls-position="right"
        placeholder="请输入低分段"
      ></el-input-number>
    </el-form-item>
    <el-form-item label="高分段">
      <el-input-number
        v-model="data.highThreshold"
        controls-position="right"
        placeholder="请输入高分段"
      ></el-input-number>
    </el-form-item>

    <el-form-item label="未选择颜色">
      <l-input-color v-model="data.voidColor" placeholder="请输入未选颜色"></l-input-color>
    </el-form-item>
    <el-form-item label="只读颜色">
      <l-input-color v-model="data.disabledVoidColor" placeholder="请输入只读颜色"></l-input-color>
    </el-form-item>
    <el-form-item label="文字颜色">
      <l-input-color v-model="data.textColor" placeholder="请输入文字颜色"></l-input-color>
    </el-form-item>

    <el-form-item label="未选择图标">
      <l-input-icon v-model="data.voidIconClass" :iconList="iconList" placeholder="请输入未选图标"></l-input-icon>
    </el-form-item>
    <el-form-item label="只读图标">
      <l-input-icon
        v-model="data.disabledVoidIconClass"
        :iconList="iconList"
        placeholder="请输入只读图标"
      ></l-input-icon>
    </el-form-item>

    <el-form-item label="允许半选">
      <el-switch v-model="data.allowHalf"></el-switch>
    </el-form-item>
    <el-form-item label="显示文字">
      <el-switch v-model="data.showText"></el-switch>
    </el-form-item>
    <el-form-item label="显示分数">
      <el-switch v-model="data.showScore"></el-switch>
    </el-form-item>
    <el-form-item label="是否必填">
      <el-switch v-model="data.required"></el-switch>
    </el-form-item>

    <el-divider>颜色组</el-divider>
    <div>
      <draggable :list="data.colors" :group="{ name: 'dic' }" ghost-class="set-item-ghost" handle=".drag-item">
        <div v-for="(item, index) in data.colors" class="set-item" :key="index">
          <i class="drag-item el-icon-rank"></i>
          <l-input-color
            size="mini"
            style="width: 100%"
            v-model="data.colors[index]"
            placeholder="颜色"
          ></l-input-color>
        </div>
      </draggable>
    </div>

    <el-divider>图标组</el-divider>
    <div>
      <draggable :list="data.iconClasses" :group="{ name: 'dic' }" ghost-class="set-item-ghost" handle=".drag-item">
        <div v-for="(item, index) in data.iconClasses" class="set-item" :key="index">
          <i class="drag-item el-icon-rank"></i>
          <l-input-icon size="mini" style="width: 100%" :iconList="iconList" v-model="data.iconClasses[index]">
          </l-input-icon>
        </div>
      </draggable>
    </div>
    <el-divider>辅助文字数组</el-divider>
    <div>
      <draggable :list="data.texts" :group="{ name: 'dic' }" ghost-class="set-item-ghost" handle=".drag-item">
        <div v-for="(item, index) in data.texts" class="set-item" :key="index">
          <i class="drag-item el-icon-rank"></i>
          <el-input size="mini" style="width: 100%" v-model="data.texts[index]"> </el-input>
        </div>
      </draggable>
    </div>

    <el-form-item label="是否可见">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
    <el-form-item label="是否只读">
      <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-icon",
  props: ["data"],
  data() {
    return {};
  },
  computed: {
    iconList() {
      if (this.lr_icons) {
        return this.lr_icons;
      } else {
        return [];
      }
    },
  },
  methods: {},
};
</script>
