<template>
  <div class="form-config-container">
    <el-form label-position="left"
             label-width="80px"
             size="mini">
        <el-form-item label="表单尺寸">
            <el-radio-group v-model="formInfo.size">
                <el-radio-button label="medium">{{$t('中等')}}</el-radio-button>
                <el-radio-button label="small">{{$t('较小')}}</el-radio-button>
                <el-radio-button label="mini">{{$t('迷你')}}</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="标签对齐">
            <el-radio-group v-model="formInfo.labelPosition">
                <el-radio-button label="right">{{$t('右')}}</el-radio-button>
                <el-radio-button label="left">{{$t('左')}}</el-radio-button>
                <el-radio-button label="top">{{$t('顶部')}}</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="标题宽度">
              <el-input-number v-model="formInfo.labelWidth"
                    controls-position="right"
                    :placeholder="$t('请输入')"
                    :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="栅格间隔">
              <el-input-number v-model="formInfo.gutter"
                    controls-position="right"
                    :placeholder="$t('请输入')"
                    :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="打开方式">
            <l-select v-model="formInfo.openType" :options="[{value:'1',label:'弹窗'},{value:'2',label:'抽屉'}]"  ></l-select>
        </el-form-item>
        <el-form-item label="弹窗宽度" v-if="formInfo.openType == '1'">
              <el-input-number v-model="formInfo.dialogWidth"
                    controls-position="right"
                    :placeholder="$t('请输入')"
                    :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="弹窗高度" v-if="formInfo.openType == '1'">
              <el-input-number v-model="formInfo.dialogHeight"
                    controls-position="right"
                    :placeholder="$t('请输入')"
                    :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="抽屉宽度" v-if="formInfo.openType == '2'">
              <el-input-number v-model="formInfo.drawerWidth"
                    controls-position="right"
                    :placeholder="$t('请输入')"
                    :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="数据历史">
            <el-radio-group v-model="formInfo.historyType">
                <el-radio-button label="0">{{$t('无')}}</el-radio-button>
                <el-radio-button label="1">{{$t('公用')}}</el-radio-button>
                <el-radio-button label="2">{{$t('私有')}}</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-divider>{{$t('表单选项卡')}}</el-divider>
        <draggable
                :list="tabList"
                :group="{ name: 'dic' }"
                ghost-class="set-item-ghost"
                handle=".drag-item">
            <div v-for="(item, index) in tabList"
                class="set-item"
                :key="index">
                <i class="drag-item el-icon-rank"></i>
                <el-input
                    size="mini"
                    v-model="item.text"
                    :placeholder="$t('选项卡名称')"></el-input>
                <el-button 
                    v-if="tabList.length > 1"
                    @click="handleRemoveTabs(index)"
                    circle
                    plain
                    type="danger"
                    size="mini"
                    icon="el-icon-minus"
                    class="delete-item"
                    style="padding: 4px;"></el-button>
            </div>
        </draggable>
        <div style="padding-left:22px;" >
            <el-button size="mini" type="text" icon="el-icon-circle-plus-outline" @click="handleAddTabs"  >{{$t('添加选项卡')}}</el-button>
        </div>
        
        <template v-if="!isNoScript">
            <el-divider>{{$t('脚本')}}</el-divider>
            <div class="mt-8" >
                <el-button @click="handleBtnClick('beforeSetData','添加赋值前脚本')"  type="primary" size="mini" >添加赋值前脚本</el-button>
                <el-button @click="handleBtnClick('afterValidateForm','添加校验后脚本')"  type="primary" size="mini" >添加校验后脚本</el-button>
            </div>
            <div class="mt-8" >
                <el-button @click="handleBtnClick('afterSaveEvent','添加保存后脚本')"  type="primary" size="mini" >添加保存后脚本</el-button>
                <el-button @click="handleBtnClick('changeDataEvent','添加数据改变脚本')"  type="primary" size="mini" >添加数据改变脚本</el-button>
            </div>
        </template>
    </el-form>
    <l-dialog 
        :title="formTitle"
        :visible.sync="formVisible"
        :height="528"
        :width="1000"
        @ok="handleCodeSave"
        @opened="handleOpenedForm"
        >
        <l-layout :right="320">
            <l-code-mirror v-model="code" isHint :handleHint="handleHint" mode="application/javascript" ></l-code-mirror>
            <template #right>
                <div class="l-rblock" style="padding:8px;overflow: auto;" >
                    <el-alert
                        title="脚本参数说明,只支持ES5语法（兼容小程序）"
                        type="warning"
                        :closable="false"
                        >
                    prop:组件id;
                    <br/>data:组件改变数据;
                    <br/>rowIndex:子表行号;
                    <br/>isUpdate:表单状态,新增或更新;
                    <br/>get(path):获取数据方法,
                    如果想获取某一个组件值就设置path参数值为组件id,
                    如果是子表的一行数据值就设置子表id.行号,
                    如果是子表某一行某一列值就设置子表id.行号.子组件id;
                    <br/>set(path,value):设置值方法,path说明和get方法一致;
                    <br/>getLabel(prop):获取组件的显示值,prop为组件id;
                    <br/>setRequired(prop,isRequired):设置组件是否必填,prop为组件id,isRequired 默认值true,如果取消必填就设置成false
                    <br/>setDisabled(prop,isDisabled):设置组件是否只读,prop为组件id,isDisabled 默认值true,如果取消只读就设置成false
                    <br/>setHide(prop,isHide):设置组件是否隐藏,isHide 默认值true,如果取消隐藏就设置成false
                    <br/>httpGet(option):get请求方法
                    <br/>httpPost(option):post请求方法
                    <br/>httpDelete(option):delete请求方法
                    <br/>httpPut(option):put请求方法
                    <br/>option:上面四个请求方法参数描述:url:请求地址,data:提交数据(get方法不支持),params:url参数,errorTips:请求失败错误提示,callback请求回调方法
                    返回结果为请求数据
                    <br/>loading:显示加载状态
                    <br/>hideLoading:隐藏加载状态
                    <br/>message:显示提示消息
                    <br/>loginUser:当前登录者信息
                    <br/>callback:回调方法,在脚本里使用了http方法后才需要使用
                    </el-alert>
                </div>
            </template>
        </l-layout>
    </l-dialog>
   
  </div>
</template>

<script>
export default {
    name: 'form-config',
    components: {
    },
    inject: ["formDesign"],
    data () {
        return {
            formTitle:'',
            formVisible:false,
            code:'',
            type:'',
        }
    },
    computed:{
        tabList(){
            return this.formDesign.formInfo.tabList;
        },
        formInfo(){
            return this.formDesign.formInfo;
        },
        isNoScript(){
            return this.formDesign.isNoScript;
        }
    },
    methods:{
        handleAddTabs(){
            this.tabList.push({
                components:[],
                text:this.$t('新建选项卡') + this.tabList.length
            });
        },
        handleRemoveTabs(index){
            if(`tab${index}` == this.formDesign.formActiveName){
                this.formDesign.formActiveName = 'tab0';
            }
            this.tabList.splice(index,1);
        },

        handleBtnClick(type,title){
            this.type = type
            this.formTitle = title
            this.formVisible = true
        },

        getEvent(strEvent){
            // 获取事件方法
            if(!this.$validatenull(strEvent)){
                if(strEvent.indexOf('=>') != -1){
                    // 表示是老版本，提示其修改为新的版本
                    console.warn('当前脚本不支持ES6语法，只支持ES5语法')
                    return  {res:false,msg:'脚本没有更新为最新的版本！'}
                }
                else{
                    strEvent = `(function(){function fn(learun) {${strEvent}} return fn})()`
                    return this.$getFunction(strEvent)
                }
            }
            else{
                return  {res:true}
            }
        },

        handleCodeSave(){
            const {res,msg} = this.getEvent(this.code)
            if(res){
                this.formInfo[this.type] = this.code
                this.formVisible = false
            }
            else{
                this.$message({
                    type: 'error',
                    message: `脚本错误:${msg}`
                })
            }
        },
        handleOpenedForm(){
            this.code = this.formInfo[this.type]
        },
        handleHint(text){
            text = text.replace(/'/g,'')
            text = text.replace(/"/g,'')
            const list = this.formDesign.nowComponentList.filter(t=>(t.label.indexOf(text) != -1 ||  t.prop.indexOf(text) != -1))
            return list.map(t=>`/*${t.label}*/"${t.prop}"`)
        }
    }
}
</script>

